import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import classes from "./CourseDetails.module.scss";
import config from "../../config";
import { CourseInterface } from "../../shared/types";

/**
 * Displays the course information for the Review page.
 * @param props courseKey of the course
 * @returns jsx of the CourseDetails
 */
function CourseDetails(props: { courseKey: string }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [courseData, setCourseData] = useState<CourseInterface>();
  const { courseKey } = props;

  /**
   * Gets information about the course and the platform it's on.
   */
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/course/${courseKey}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setCourseData(data.data);
        });
    }
  }, [courseKey]);

  return (
    <div>
      <div className={classes["topmost-container"]}>
        <img src={courseData?.image_blob_name} className={classes.thumbnail} alt="course" />
        <div className={classes.CourseInfo}>
          <a href={courseData?.url} target="_blank" rel="noopener noreferrer">
            {courseData?.title}
          </a>
          <div className={classes.Platform}>Platform: {courseData?.platform_name}</div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
