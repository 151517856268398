import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./SearchResultsPage.module.scss";
import Header from "../shared/Header/Header";
import SearchResultComponent from "./SearchResultComponent/SearchResultComponent";
import config from "../config";
import IconSoftwareDev from "../assets/software-development 1.png";
import IconBusiness from "../assets/PinClipart 1.png";
import IconPersonalDev from "../assets/personal-development 1.png";
import IconEmerging from "../assets/emerge.png";
import IconUXDesign from "../assets/Seek.png";
import IconTheArts from "../assets/arts.png";
import IconEngineering from "../assets/engineering.png";
import IconLeadership from "../assets/leadership.png";
import IconMSIInternal from "../assets/msi-internal.png";
import IconChevronLeft from "../assets/chevron_left.svg";
import IconChevronRight from "../assets/chevron_right.svg";
import { CategoryInterface } from "../shared/types";
import Mixpanel from "../shared/MixPanelService";

interface CourseSearchResultInterface {
  id: string;
  platform_name: string;
  title: string;
  url: string;
  description: string;
  duration_hours: number;
  added_date: string;
  image_blob_name: string;
  cost: number;
  average_rating: number;
  categories: CategoryInterface[];
  review: string;
}

/**
 * Custom hook that builds on userLocation to parse query string
 * @returns
 */
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Displays search results for user's search
 *    - Depend on URL query for search
 */
function SearchResultsPage() {
  const { authState, oktaAuth } = useOktaAuth();
  const [courseResults, setcourseResults] = useState<CourseSearchResultInterface[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageCounter, setPageCounter] = useState<number>(1);
  const numberOfCoursesOnPage = 3;

  const courseSearch = useQuery().get("courseSearch");
  const categorySearch = useQuery().get("category");

  /**
   * Calls API's required to display information on page
   * -  Call either the courseSearch or categorty search API endpoint depending on what query
   *    paramter (courseSearch or categorySearch) is filled
   */
  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "SearchResultsPage",
      SearchType: !categorySearch ? "Custom Search" : "Category Search",
      Search: !categorySearch ? courseSearch : categorySearch,
    });

    if (authState && authState.isAuthenticated) {
      if (categorySearch) {
        // bugfix: checks if UX/Design beacuse we cannot pass encoded "UX/DESIGN" to backend
        // with it reading properly
        let categoryParam = categorySearch;
        if (categorySearch === "UX/Design") {
          categoryParam = "UX-Design";
        }
        fetch(
          `${config.resourceServer}/courseCategory/${encodeURIComponent(categoryParam)}/course`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setcourseResults(data.data);
            setTotalPages(Math.ceil(data.data.length / numberOfCoursesOnPage));
          });
      } else if (courseSearch) {
        fetch(`${config.resourceServer}/course/search/${encodeURIComponent(courseSearch)}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
        })
          .then((response) => response.json())
          .then((data) => {
            setcourseResults(data.data);
            setTotalPages(Math.ceil(data.data.length / numberOfCoursesOnPage));
          });
      }
    }
  }, [categorySearch, courseSearch]);

  /**
   * Updates the counter for total number of page every time courseResults or numberOfCoursesOnPage
   * updated
   */
  useEffect(
    () => setTotalPages(Math.ceil(courseResults.length / numberOfCoursesOnPage)),
    [courseResults, numberOfCoursesOnPage],
  );

  /**
   * Function that runs when the previous button page is clicked
   * -  Decrements the current page counter if not at 0
   */
  const onPrevPageClick = () => {
    setPageCounter((): number => {
      if (pageCounter - 1 > 0) {
        return pageCounter - 1;
      }
      return pageCounter;
    });
  };

  /**
   * Function that runs when the next button page is clicked
   * -  Increments the current page counter if not at max
   */
  const onNextPageClick = () => {
    setPageCounter((): number => {
      if (pageCounter + 1 <= totalPages) {
        return pageCounter + 1;
      }
      return pageCounter;
    });
  };

  return (
    <div>
      {categorySearch ? (
        <Header
          heading={`${categorySearch} Courses`}
          image={
            categorySearch === "Software Development"
              ? IconSoftwareDev
              : categorySearch === "Business"
              ? IconBusiness
              : categorySearch === "Personal Development"
              ? IconPersonalDev
              : categorySearch === "Emerging Tech"
              ? IconEmerging
              : categorySearch === "UX/Design"
              ? IconUXDesign
              : categorySearch === "The Arts"
              ? IconTheArts
              : categorySearch === "Engineering"
              ? IconEngineering
              : categorySearch === "Leadership"
              ? IconLeadership
              : categorySearch === "MSI Internal"
              ? IconMSIInternal
              : undefined
          }
        />
      ) : (
        <Header heading={`Search Results: "${courseSearch}"`} />
      )}
      <div className={styles.search_wrapper}>
        <h3 className={styles.search_header}>Top Courses:</h3>
        {courseResults ? (
          courseResults
            .slice(numberOfCoursesOnPage * (pageCounter - 1), numberOfCoursesOnPage * pageCounter)
            .map((course) => (
              <Link to={`/course/${course.id}`} style={{ textDecoration: "none" }}>
                <SearchResultComponent
                  key={course.id}
                  CourseKey={course.id}
                  CourseTitle={course.title}
                  CourseImage={course.image_blob_name}
                  CourseCategories={course.categories.map((category) => category.name)}
                  CourseCost={course.cost}
                  CourseDuration={course.duration_hours}
                  CoursePlatform={course.platform_name}
                  CourseRating={course.average_rating}
                  CourseReview={course.review}
                />
              </Link>
            ))
        ) : (
          <div />
        )}
        <h3 className={styles.search_footer}>
          Trying to get credit and can&apos;t find the course you are looking for?
          <Link to="/course/create" className={styles.search_footer_button}>
            Add Course
          </Link>
        </h3>
        <div className={styles.paging_navigation}>
          <button onClick={onPrevPageClick} type="button">
            <img src={IconChevronLeft} alt="" />
          </button>
          <button type="button">{pageCounter}</button>
          <div className={styles.test}>/{totalPages}</div>
          <button onClick={onNextPageClick} type="button">
            <img src={IconChevronRight} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchResultsPage;
