import React, { useCallback, Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import classes from "./ReviewDeleteModal.module.scss";

interface CourseDeleteModalProp {
  setReviewDeletionModalCoreId: Dispatch<SetStateAction<string | undefined>>;
  onDelete: () => void;
}

function ReviewDeleteModal({ setReviewDeletionModalCoreId, onDelete }: CourseDeleteModalProp) {
  const closeModal = useCallback(() => {
    setReviewDeletionModalCoreId(undefined);
  }, [setReviewDeletionModalCoreId]);

  const deleteHandler = () => {
    onDelete();
  };

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      contentLabel="Delete Modal"
      className={classes.PopupWindow}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
      parentSelector={() => document.body}
    >
      <b>Review Deletion Confirmation</b>
      <hr />
      <div>
        <p>Are you sure you want to delete this review?</p>
        <div className={classes.buttons}>
          <button type="button" className={classes.cancel} onClick={closeModal}>
            Cancel
          </button>
          <button type="button" onClick={deleteHandler}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ReviewDeleteModal;
