import React, { useState, useEffect, MouseEvent, Fragment } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useParams, useHistory, Link } from "react-router-dom";
import Avatar from "react-avatar";
import classes from "./CourseDetailPage.module.scss";
import emptyStar from "../assets/images/empty_star.png";
import filledStar from "../assets/images/filled_star.png";
import config from "../config";
import { OktaUserCoreID } from "../shared/OktaUserInfo";
import Header from "../shared/Header/Header";
import ShareButtonModal from "./ShareButtonModal/ShareButtonModal";
import ic_arrow_sort_down from "../assets/ic_arrow_sort_down.svg";
import ic_arrow_sort_up from "../assets/ic_arrow_sort_up.svg";
import CourseDeleteModal from "./CourseDeleteModal/CourseDeleteModal";
import EditIcon from "../assets/images/edit_icon.svg";
import TrashIcon from "../assets/images/ic_trash.svg";
import ShareIcon from "../assets/images/ic_share.svg";
import Mixpanel from "../shared/MixPanelService";
import ReviewDeleteModal from "./ReviewDeleteModal/ReviewDeleteModal";
import { CourseInterface, ReviewInterface } from "../shared/types";

interface ReviewWithEmployeeNameInterface extends ReviewInterface {
  first_name: string;
  last_name: string;
}

function CourseDetailPage() {
  const sortOptions = ["Course Rating", "Most Recent", "Most Helpful"];
  const [isArrowAscending, setIsArrowAscending] = useState<boolean>(false);
  const [sortSelect, setSortSelect] = useState<number>(1);
  const [arrowState, setArrowState] = useState<string>(ic_arrow_sort_down);
  const { authState, oktaAuth } = useOktaAuth();
  const { courseKey } = useParams<Record<string, string>>();
  const [courseDetails, setCourseDetails] = useState<CourseInterface>();
  const [courseReviews, setCourseReviews] = useState<ReviewWithEmployeeNameInterface[]>();
  const [deleteFailure, setDeleteFailure] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isCourseDeleteModalOpen, setIsCourseDeleteModalOpen] = useState<boolean>(false);
  const [reviewDeletionModalCoreId, setReviewDeletionModalCoreId] = useState<string | undefined>(
    undefined,
  );

  const history = useHistory();
  const coreID = OktaUserCoreID() as string;

  /**
   * Calls API's required to display information on page
   * - Recieves all information of a course by CourseKey
   */
  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "CourseDetailsPage",
      Course: courseKey,
    });

    if (authState && authState.isAuthenticated) {
      // Check if the user is an admin
      fetch(`${config.resourceServer}/admin/${coreID}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "User is not an admin") {
            setIsAdmin(false);
          } else {
            setIsAdmin(true);
          }
        });

      fetch(`${config.resourceServer}/course/${courseKey}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setCourseDetails(data.data);
        });
      fetch(`${config.resourceServer}/course/${courseKey}/review`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setCourseReviews(data.data);
        });
    }
  }, [courseKey]);
  /**
   * Function that runs when user clicks on Add to Wishlist Button
   * - Adds Course to wishlist with API
   * - Routes user to their wishlist
   * @param    {MouseEvent<HTMLButtonElement>} event    Description
   */
  const onAddToWishListClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fetch(`${config.resourceServer}/wish`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        employee_core_id: coreID,
        course_id: courseKey,
        added_date: new Date(),
      }),
    })
      .then((response) => response.json())
      .then(() => {
        history.push("/profile?tab=wishlistTab");
      });
  };

  /**
   * Function that runs when user clicks on Helpful Button
   * - If this user had previously marked this helpful, removes
   * this user from the Helpful user array and decrements the count.
   * - If this user had not previously marked this review as helpful,
   * adds this user to the Helpful user array and increments the count.
   */
  const onMarkHelpful = (event: MouseEvent<HTMLButtonElement>, review_idx: number) => {
    event.preventDefault();
    const tempReviews = [...courseReviews!];
    // Check if this user already marked this review as Helpful
    if (!tempReviews[review_idx].helpful_user_list.includes(coreID)) {
      // User is marking it helpful - increment count and add them to list
      tempReviews[review_idx].helpful_count += 1;
      tempReviews[review_idx].helpful_user_list.push(coreID);
    } else if (tempReviews[review_idx].helpful_count > 0) {
      // User is removing their helpful count - decrement counter, remove from list
      tempReviews[review_idx].helpful_count -= 1;
      const indexOfObject = tempReviews[review_idx].helpful_user_list.findIndex((object) => {
        return object === coreID;
      });
      // Make sure the array index is valid
      if (indexOfObject !== -1) {
        tempReviews[review_idx].helpful_user_list.splice(indexOfObject, 1);
      }
    }
    setCourseReviews(tempReviews);
    // Send update request to backend for DB update
    fetch(`${config.resourceServer}/review/helpful`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        employee_core_id: tempReviews[review_idx].employee_core_id,
        course_id: parseFloat(courseKey),
        helpful_core_id: coreID,
        helpful_active: Boolean(!tempReviews[review_idx].helpful_user_list.includes(coreID)),
      }),
    })
      .then((response) => response.json())
      .then(() => {});
  };

  /**
   * Function that runs when user clicks on Inappropriate Button
   * If a user selects the inappropriate button the review will still
   * be shown but will have an indication it's been marked as
   * inappropriate.  We do store the coreIDs of the users marking this
   * review as inappropriate.
   */
  const onMarkInappropriate = (event: MouseEvent<HTMLButtonElement>, review_idx: number) => {
    event.preventDefault();
    const tempReviews = [...courseReviews!];
    tempReviews[review_idx].is_appropriate = false;
    tempReviews[review_idx].inappropriate_reporter = coreID;
    setCourseReviews(tempReviews);
    // Send backend request to update DB
    fetch(`${config.resourceServer}/review/inappropriate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        employee_core_id: courseReviews![review_idx].employee_core_id,
        course_id: parseFloat(courseKey),
        is_appropriate: false,
        inappropriate_reporter: coreID,
      }),
    })
      .then((response) => response.json())
      .then(() => {});
  };
  /**
   *  Updates sort listing depending on category (Most Helpful, Most Recent)
   *  and order (Ascending, Descending)
   *  sortSel - sort by category
   *  isArrowUp - sort by Ascending or Descending
   */
  function renderList(sortSel: number = sortSelect, isArrowUp: boolean = isArrowAscending) {
    if (courseReviews === undefined) {
      // disables if there are no reviews
      return;
    }
    let updatedListing: ReviewWithEmployeeNameInterface[] = [];
    if (sortSel === 0) {
      updatedListing = isArrowUp
        ? courseReviews.sort(
            (a: ReviewWithEmployeeNameInterface, b: ReviewWithEmployeeNameInterface) => {
              return a.rating - b.rating;
            },
          )
        : courseReviews.sort(
            (a: ReviewWithEmployeeNameInterface, b: ReviewWithEmployeeNameInterface) => {
              return b.rating - a.rating;
            },
          );
    } else if (sortSel === 1) {
      updatedListing = isArrowUp
        ? courseReviews.sort(function (
            a: ReviewWithEmployeeNameInterface,
            b: ReviewWithEmployeeNameInterface,
          ) {
            return a.created_date.localeCompare(b.created_date);
          })
        : courseReviews.sort(function (
            a: ReviewWithEmployeeNameInterface,
            b: ReviewWithEmployeeNameInterface,
          ) {
            return b.created_date.localeCompare(a.created_date);
          });
    } else if (sortSel === 2) {
      updatedListing = isArrowUp
        ? courseReviews.sort(
            (a: ReviewWithEmployeeNameInterface, b: ReviewWithEmployeeNameInterface) => {
              return a.helpful_count - b.helpful_count;
            },
          )
        : courseReviews.sort(
            (a: ReviewWithEmployeeNameInterface, b: ReviewWithEmployeeNameInterface) => {
              return b.helpful_count - a.helpful_count;
            },
          );
    }
    setCourseReviews(updatedListing);
  }
  /**
   *  Updates sort listing depending on category (Most Helpful, Most Recent)
   *  sortSelect - sort by category
   *  isArrowAscending - sort by Ascending or Descending
   */
  function changeSort() {
    renderList(sortSelect, !isArrowAscending);
    if (isArrowAscending) setArrowState(ic_arrow_sort_down);
    else setArrowState(ic_arrow_sort_up);
    setIsArrowAscending(!isArrowAscending);
  }
  /**
   *  changeCategory: changes what category the listing sorts by
   *  0 = Course Rating
   *  1 = Most Recent
   *  2 = Most Helpful
   *  category: category to sort by
   */
  function changeCategory(category: string) {
    if (category === "Course Rating") {
      renderList(0);
      setSortSelect(0);
    } else if (category === "Most Recent") {
      renderList(1);
      setSortSelect(1);
    } else if (category === "Most Helpful") {
      renderList(2);
      setSortSelect(2);
    }
  }
  /**
   * On Click of a Course's delete button this function will call the resourse server to delete the course
   */
  const deleteCourseHandler = () => {
    fetch(`${config.resourceServer}/course/${courseKey}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
    }).then((response) => {
      response.json().then(() => {
        if (response.ok) {
          // Redirect to Homepage
          history.push("/");
        } else {
          setDeleteFailure(true);
        }
      });
    });
  };

  /**
   * On Click of a Review's delete button this function will call the resourse server to delete the review
   */
  const deleteReviewHandler = () => {
    fetch(
      `${config.resourceServer}/employee/${reviewDeletionModalCoreId}/course/${courseKey}/review`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setReviewDeletionModalCoreId(undefined);
        fetch(`${config.resourceServer}/course/${courseKey}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
        })
          .then((courseUpdateResponse) => courseUpdateResponse.json())
          .then((courseUpdateData) => {
            setCourseDetails(courseUpdateData.data);
          });
        const index = courseReviews!.findIndex(
          (el) => el.employee_core_id === data.data.employee_core_id,
        );
        if (index > -1) {
          courseReviews!.splice(index, 1);
        }
      });
  };

  /**
   * On click of the edit button, the user will be redirected to the
   * New Course Page to update the course information
   */
  const editCourseHandler = () => {
    history.push(`/course/${courseKey}/update`);
  };

  return (
    <div>
      <Header heading="Course Listing" usesAclonicaFontHeading={false} />

      {deleteFailure && (
        <div className={classes.delete_failure}>
          <span>An error occurred while deleting the course. Please try again.</span>
        </div>
      )}

      {isShareModalOpen && <ShareButtonModal setIsModalOpen={setIsShareModalOpen} />}

      {isCourseDeleteModalOpen && (
        <CourseDeleteModal
          setIsModalOpen={setIsCourseDeleteModalOpen}
          onDelete={deleteCourseHandler}
        />
      )}

      {reviewDeletionModalCoreId && (
        <ReviewDeleteModal
          setReviewDeletionModalCoreId={setReviewDeletionModalCoreId}
          onDelete={deleteReviewHandler}
        />
      )}

      <div className={classes.wrapper}>
        <div className={classes["topmost-container"]}>
          <img
            src={courseDetails?.image_blob_name}
            className={classes.thumbnail}
            alt="Course Thumbnail"
          />
          <div className={classes.link}>
            <a
              className={classes.courseTitle}
              href={courseDetails?.url}
              target="_blank"
              rel="noreferrer"
            >
              {courseDetails?.title}
            </a>
            <div className={classes["content-data"]}>
              <div className={classes.platform}>Platform: {courseDetails?.platform_name}</div>
              <div className={classes["star-review"]}>
                Average Rating:
                <div className={classes.stars}>
                  {[...Array(5)].map((e, i) => {
                    if (courseDetails?.average_rating) {
                      if (i < courseDetails?.average_rating) {
                        return (
                          <img
                            key={`filled ${i / courseDetails!.average_rating}`}
                            src={filledStar}
                            alt="filledStar"
                          />
                        );
                      }
                      return (
                        <img
                          key={`empty ${i / courseDetails!.average_rating}`}
                          src={emptyStar}
                          alt="emptyStar"
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className={classes.duration}>
                Duration: {courseDetails?.duration_hours} hours
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          {courseReviews?.some((el) => el.employee_core_id === coreID) ? (
            <Link to={`/course/${courseDetails?.id}/review/${coreID}`}>
              <button className={classes.button} type="button">
                Edit Review
              </button>
            </Link>
          ) : (
            <Link to={`/course/${courseDetails?.id}/review`}>
              <button className={classes.button} type="button">
                Review & Get Credit
              </button>
            </Link>
          )}
          <button className={classes.button} onClick={onAddToWishListClick} type="button">
            Add to Wishlist
          </button>
          <img
            src={ShareIcon}
            alt="share_icon"
            className={classes.edit_icon}
            onClick={() => {
              setIsShareModalOpen(true);
            }}
          />
          {(isAdmin || courseDetails?.course_creator === coreID) && (
            <>
              <img
                src={EditIcon}
                alt="edit_icon"
                className={classes.edit_icon}
                onClick={editCourseHandler}
              />
              <img
                src={TrashIcon}
                alt="trash_icon"
                className={classes.trash_icon}
                onClick={() => {
                  setIsCourseDeleteModalOpen(true);
                }}
              />
            </>
          )}
        </div>
        <div className={classes.description}>Description:</div>
        <div className={classes.paragraph}>{courseDetails?.description}</div>
        <div className={classes["reviews-text"]}>Reviews:</div>
        <div className={classes["reviews-sort-text"]}>
          <div className={classes.dropBtnField}>
            <div>
              Sort:
              <select
                value={sortOptions[sortSelect]}
                onChange={(e) => changeCategory(e.target.value)}
                className={classes.dropBtn}
              >
                {sortOptions.map((value) => (
                  <option className={classes.link}> {value} </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <img
              className={classes.arrowImg}
              src={arrowState}
              alt="Arrow"
              onClick={() => changeSort()}
            />
          </div>
        </div>
        {courseReviews?.map((review, review_idx) => (
          <div key={review.employee_core_id}>
            <div className={classes["reviewer-profile"]}>
              <Avatar name={`${review.first_name + review.last_name}`} size="40" round />
              <div className={classes.reviewersName}>
                {review.first_name} {review.last_name}
              </div>
            </div>
            <div className={classes["star-review"]}>
              <div className={classes.stars}>
                {[...Array(5)].map((e, i) => {
                  if (i < review.rating) {
                    return (
                      <img key={`filled ${i / review.rating}`} src={filledStar} alt="filledStar" />
                    );
                  }
                  return (
                    <img key={`empty ${i / (5 - review.rating)}`} src={emptyStar} alt="emptyStar" />
                  );
                })}
              </div>
              <div className={classes["review-date"]}>
                {new Date(review.created_date).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                })}
              </div>
              {(review.employee_core_id === coreID || isAdmin) && (
                <>
                  <Link to={`/course/${courseDetails?.id}/review/${review.employee_core_id}`}>
                    <img src={EditIcon} alt="edit_icon" className={classes.edit_icon} />
                  </Link>
                  <img
                    src={TrashIcon}
                    alt="trash_icon"
                    className={classes.trash_icon}
                    onClick={() => {
                      setReviewDeletionModalCoreId(review.employee_core_id);
                    }}
                  />
                </>
              )}
            </div>
            <div className={classes.price}>Price Paid: ${review.cost.toFixed(2)}</div>
            <div> {review.review}</div>
            {!review?.is_appropriate ? (
              <div className={classes.helpfultext}>
                This review has been flagged for inappropriate content
              </div>
            ) : (
              <div className={classes.helpfultext}>
                {review.helpful_count} {review.helpful_count === 1 ? "Motorolan" : "Motorolans"}{" "}
                found this helpful
              </div>
            )}
            <div className={classes.reviewbuttons}>
              <button
                className={
                  review.helpful_user_list.includes(coreID) ? classes.nothelpful : classes.helpful
                }
                onClick={(event) => onMarkHelpful(event, review_idx)}
                type="button"
              >
                Helpful
              </button>
              {review.is_appropriate && (
                <button
                  className={classes.inappropriate}
                  onClick={(event) => onMarkInappropriate(event, review_idx)}
                  type="button"
                >
                  Report Abuse
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseDetailPage;
