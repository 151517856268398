import { useOktaAuth } from "@okta/okta-react";

function OktaUserCoreID() {
  const coreIDEmail = useOktaAuth().authState?.idToken?.claims.preferred_username;
  return coreIDEmail?.substring(0, coreIDEmail.indexOf("@"));
}

function OktaUserFirstName() {
  const fullName = useOktaAuth().authState?.idToken?.claims.name;
  return fullName?.substring(0, fullName.indexOf(" "));
}

function OktaUserLastName() {
  const fullName = useOktaAuth().authState?.idToken?.claims.name;
  return fullName?.substring(fullName.indexOf(" ") + 1);
}

function OktaUserEmail() {
  return useOktaAuth().authState?.idToken?.claims.email;
}

function OktaAccessToken() {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  return accessToken;
}

export { OktaUserCoreID, OktaUserFirstName, OktaUserLastName, OktaUserEmail, OktaAccessToken };
