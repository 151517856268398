import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import classes from "./ReviewInputForm.module.scss";
import ReviewInputBox from "./ReviewInputBox/ReviewInputBox";
import GuidelinesPopUp from "./GuidelinesPopUp/GuidelinesPopUp";
import config from "../../config";
import { CategoryInterface } from "../../shared/types";
import Mixpanel from "../../shared/MixPanelService";
import { OktaUserCoreID } from "../../shared/OktaUserInfo";

interface ReviewInputFormProp {
  courseKey: string;
  reviewCoreId: string;
}

/**
 * Form for users to enter their review of a course.
 * @param props courseKey for the course
 * @returns jsx for the ReviewInputForm
 */
function ReviewInputForm({ courseKey, reviewCoreId }: ReviewInputFormProp) {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [rating, setRating] = useState(0);
  const [starHover, setStarHover] = useState(-1);
  const [review, setReview] = useState("");
  const [reviewHasError, setReviewHasError] = useState(false);
  const [price, setPrice] = useState("");
  const [priceHasError, setPriceHasError] = useState(false);
  const [loadedCategories, setLoadedCategories] = useState<CategoryInterface[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const [isUpdateAuthorized, setIsUpdateAuthorized] = useState(true);
  const coreID = OktaUserCoreID() as string;

  /**
   * Load in all the course categories from the database.
   */
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/category`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoadedCategories(data.data);
        });
      fetch(`${config.resourceServer}/course/${courseKey}/category`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setSelectedCategories(data.data.map((el: CategoryInterface) => el.name));
        });
      // If we have a reviewCoreId and looking to edit review, pull relevant review information
      if (reviewCoreId !== undefined) {
        fetch(`${config.resourceServer}/employee/${reviewCoreId}/course/${courseKey}/review`, {
          method: "GET",
          headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 200) {
              // Check if the user is an admin
              let isAdmin = false;
              fetch(`${config.resourceServer}/admin/${coreID}`, {
                method: "GET",
                headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
              })
                .then((response) => response.json())
                .then((adminData) => {
                  if (adminData.message !== "User is not an admin") {
                    isAdmin = true;
                  }

                  // If user is not the creator of the review or is not an admin,
                  // prevent the user from updating the review
                  if (coreID !== data.data.employee_core_id && !isAdmin) {
                    setIsUpdateAuthorized(false);
                  } else {
                    setRating(data.data.rating);
                    setStarHover(data.data.rating);
                    setReview(data.data.review);
                    setPrice(data.data.cost);
                  }
                });
            }
          });
      }
    }
  }, []);

  /**
   * Takes the review from ReviewInputBox, checks if it is not empty, and sets it if so.
   * @param newReview written by the user
   */
  const reviewChangeHandler = (newReview: string) => {
    if (newReview !== "") {
      setReview(newReview);
      setReviewHasError(false);
    } else {
      setReviewHasError(true);
    }
  };

  /**
   * Keeps track of the categories the user has selected. If the user has not selected the category,
   * it is added to the selectedCategories array. If they have already selected it and has clicked on it again,
   * it is removed from the selectedCategories array.
   * @param categoryName user has selected
   */
  const categoryButtonClickHandler = (categoryName: string) => {
    if (selectedCategories.includes(categoryName)) {
      setSelectedCategories(selectedCategories.filter((category) => category !== categoryName));
    } else {
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  /**
   * Checks if the entered price includes a $ sign and sets it.
   * @param event ChangeEvent from user entering the price
   */
  const priceChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setPrice(input);
    /* If input is not a number, don't allow.   This is redundant 
      with the HTML input being specific as number.  In the future, if we want to
      give more descriptive errors, we can use the type of string and then use the
      code below to remove the bad input and give an error reason */
    if (!Number.isNaN(+input) && parseFloat(input) >= 0) {
      setPriceHasError(false);
    } else {
      setPriceHasError(true);
    }
  };

  // Checks if all entries are filled out correctly.
  let formIsValid = false;
  if (
    rating > 0 &&
    !reviewHasError &&
    !priceHasError &&
    selectedCategories.length > 0 &&
    isCheckboxChecked
  ) {
    formIsValid = true;
  }

  /**
   * Sends entered information to the database. Creates a new Review edge and BelongsTo (if needed) edges.
   * @param event MouseEvent from submission button click
   */
  const formSubmitHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    fetch(`${config.resourceServer}/review/category`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        employee_core_id: reviewCoreId,
        course_id: parseFloat(courseKey),
        rating,
        cost: parseFloat(price),
        created_date: new Date(),
        review,
        categories: selectedCategories,
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          setIsFormSubmitted(true);
          Mixpanel.track("NewReviewPage - Created Review", {
            Course: courseKey,
          });
          history.push(`/course/${courseKey}`);
        } else if (data.data.data === "Edge already exists") {
          setFormHasError(true);
        }
      });
    });
  };

  return (
    <div>
      {isUpdateAuthorized ? (
        <div className={classes.wrapper}>
          <div>
            <GuidelinesPopUp />
          </div>
          <div className={classes.StarRatingBar}>
            <span>*Rating:</span>
            <div className={classes.StarIcons} data-testid="rating_stars">
              {[...Array(5)].map((star, index) => {
                const currIndex = index + 1;
                return (
                  <button
                    type="button"
                    key={currIndex}
                    className={
                      currIndex <= (starHover || rating) ? classes.StarActive : classes.StarInactive
                    }
                    onClick={() => setRating(currIndex)}
                    onMouseEnter={() => setStarHover(currIndex)}
                    onMouseLeave={() => setStarHover(rating)}
                  >
                    <span>&#9733;</span>
                  </button>
                );
              })}
            </div>
          </div>
          <div className={classes.ReviewInput}>
            *Review:
            <ReviewInputBox initialReview={review} onChangeReview={reviewChangeHandler} />
          </div>
          <div className={classes.PriceInput}>
            *Price Paid (USD): $
            <input
              type="number"
              value={price}
              onChange={priceChangeHandler}
              onBlur={(e) => setPriceHasError(e.target.value === "")}
              placeholder="Enter Price Paid in USD (e.g. 12.99)"
            />
            {priceHasError && <b className={classes.ErrorText}>Enter price paid in USD.</b>}
          </div>
          <div className={classes.Categories}>
            *Select Course Category(s):
            <div>
              {loadedCategories.map((category) => (
                <button
                  type="button"
                  key={category.name}
                  onClick={() => categoryButtonClickHandler(category.name)}
                  className={
                    classes[
                      selectedCategories.includes(category.name)
                        ? "ButtonPressed"
                        : "ButtonNotPressed"
                    ]
                  }
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
          <div className={classes.Checkbox}>
            <label htmlFor="completion_checkbox">
              <input
                type="checkbox"
                id="completion_checkbox"
                onChange={() => setIsCheckboxChecked((current) => !current)}
              />
              *I have completed this course - add to my training inventory
            </label>
          </div>

          {formHasError && (
            <div className={classes.FormFailure}>
              <span>You have already created a review for this course!</span>
            </div>
          )}

          <div className={classes.SubmitButton}>
            <button type="button" disabled={!formIsValid} onClick={formSubmitHandler}>
              Post Review
            </button>
          </div>
          {isFormSubmitted && (
            <div className={classes.SuccessMessage}>
              <b>Review Successful!</b>
            </div>
          )}
        </div>
      ) : (
        <h2 className={classes.not_authorized}>You are not authorized to update this review!</h2>
      )}
    </div>
  );
}

export default ReviewInputForm;
