/* eslint "react/no-array-index-key": off */
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence, Variants } from "framer-motion";
import CourseCarouselCard from "./CourseCarouselCard/CourseCarouselCard";
import styles from "./CourseCarousel.module.scss";
import config from "../../config";
import useWindowDimensions from "../../shared/useWindowDimensions";
import { CourseInterface, ReviewInterface, EmployeeInterface } from "../../shared/types";

interface CourseSliderInterface {
  course: CourseInterface;
  review: ReviewInterface;
  employee: EmployeeInterface;
}

/**
 * A scrolling carousel of 3 sections (recently reviewed, recentlyADded, and highest) rated with
 * set timer moving between them
 * @returns jsx for CourseCarousel
 */
function CourseCarousel() {
  const [currentSlideNumber, setSlideNumber] = useState<number>(0);
  const [coursesRecentlyReviewed, setCoursesRecentlyReviewed] = useState<CourseSliderInterface[]>(
    [],
  );
  const [coursesRecentlyAdded, setCoursesRecentlyAdded] = useState<CourseSliderInterface[]>([]);
  const [coursesHighlyRated, setCoursesHighlyRated] = useState<CourseSliderInterface[]>([]);
  const { width } = useWindowDimensions();
  const totalNumberOfSlides = 3;
  const variants: Variants = {
    enter: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -500 },
  };

  /**
   * Calls API's required to display information on page
   * - 3 APIs one for each section of the carousel
   */
  useEffect(() => {
    fetch(`${config.resourceServer}/course/recentlyReviewed`)
      .then((response) => response.json())
      .then((data) => {
        setCoursesRecentlyReviewed(data.data);
      });
    fetch(`${config.resourceServer}/course/recentlyAdded`)
      .then((response) => response.json())
      .then((data) => {
        setCoursesRecentlyAdded(data.data);
      });
    fetch(`${config.resourceServer}/course/highestRated`)
      .then((response) => response.json())
      .then((data) => {
        setCoursesHighlyRated(data.data);
      });
  }, []);

  /**
   * An interval Timer that rotates what slide is currently showing every 3000 milliseconds
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setSlideNumber(
        (currentSlideNumberCopy) => (currentSlideNumberCopy + 1) % totalNumberOfSlides,
      );
    }, 7000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.course_carousel}>
      <AnimatePresence initial={false} mode="wait">
        {currentSlideNumber === 0 ? (
          <motion.div
            variants={variants}
            initial="enter"
            animate="visible"
            exit="exit"
            transition={{ type: "tween" }}
            key="RecentlyReviewed"
          >
            <h1 className={styles.carousel_header}>RECENT REVIEWS</h1>
            <div className={styles.carousel_cards}>
              {coursesRecentlyReviewed
                .slice(0, width != null && width < 800 ? 2 : 3)
                .map((course) => (
                  <CourseCarouselCard
                    key={`RecentlyReviewed:${course.course.id}:${course.review.employee_core_id}`}
                    carousel_slide="RecentlyReviewed"
                    course_id={course.course.id}
                    firstName={course.employee.first_name}
                    lastName={course.employee.last_name}
                    image={course.course.image_blob_name}
                    title={course.course.title}
                    review={course.review.review}
                    rating={course.review.rating}
                  />
                ))}
            </div>
          </motion.div>
        ) : currentSlideNumber === 1 ? (
          <motion.div
            variants={variants}
            initial="enter"
            animate="visible"
            exit="exit"
            transition={{ type: "tween" }}
            key="RecentlyAdded"
          >
            <div>
              <h1 className={styles.carousel_header}>RECENTLY ADDED COURSES</h1>
              <div className={styles.carousel_cards}>
                {coursesRecentlyAdded
                  .slice(0, width != null && width < 800 ? 2 : 3)
                  .map((course) => (
                    <CourseCarouselCard
                      key={`RecentlyAdded:${course.course.id}:${course.review.employee_core_id}`}
                      carousel_slide="RecentlyAdded"
                      course_id={course.course.id}
                      firstName={course.employee.first_name}
                      lastName={course.employee.last_name}
                      image={course.course.image_blob_name}
                      title={course.course.title}
                      review={course.review.review}
                      rating={course.review.rating}
                    />
                  ))}
              </div>
            </div>
          </motion.div>
        ) : currentSlideNumber === 2 ? (
          <motion.div
            variants={variants}
            initial="enter"
            animate="visible"
            exit="exit"
            transition={{ type: "tween" }}
            key="HighestRated"
          >
            <div>
              <h1 className={styles.carousel_header}>HIGH RATED COURSES</h1>
              <div className={styles.carousel_cards}>
                {coursesHighlyRated.slice(0, width != null && width < 800 ? 2 : 3).map((course) => (
                  <CourseCarouselCard
                    key={`HighestRated:${course.course.id}:${course.review.employee_core_id}`}
                    carousel_slide="HighestRated"
                    course_id={course.course.id}
                    firstName={course.employee.first_name}
                    lastName={course.employee.last_name}
                    image={course.course.image_blob_name}
                    title={course.course.title}
                    review={course.review.review}
                    rating={course.review.rating}
                  />
                ))}
              </div>
            </div>
          </motion.div>
        ) : (
          <div>Something Broke</div>
        )}
      </AnimatePresence>
      <div className={styles.pagination}>
        {[...Array(totalNumberOfSlides)].map((el, iter) => {
          if (iter === currentSlideNumber) {
            return (
              <button
                onClick={() => setSlideNumber(iter)}
                className={styles.circle_filled}
                type="button"
                key={iter}
              />
            );
          }
          return (
            <button
              onClick={() => {
                setSlideNumber(iter);
              }}
              className={styles.circle_empty}
              type="button"
              key={iter}
            />
          );
        })}
      </div>
    </div>
  );
}
export default CourseCarousel;
