import React, { useState, ChangeEvent, useEffect } from "react";
import classes from "./ImageUpload.module.scss";
import UploadIcon from "../../../assets/images/upload_arrow.png";
import CheckmarkIcon from "../../../assets/images/green_checkmark.png";

/**
 * Takes in user input for a course image.
 * @param props isRefreshed, onFileUpload handler
 * @returns jsx for ImageUpload
 */
function ImageUpload(props: { isRefreshed: boolean; onFileUpload: (file: File | null) => void }) {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isImageValidSize, setIsImageValidSize] = useState(true);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const { isRefreshed, onFileUpload } = props;

  // 800KB is the largest size our server will accept
  const MAX_FILE_SIZE = 800;

  /**
   * Resets the upload checkmark whenever the page is refreshed, a form is successfully
   * submitted, or there was an error with the form submission.
   */
  useEffect(() => {
    setIsImageUploaded(false);
  }, [isRefreshed]);

  /**
   * The default "Choose Image" button's apperance is hidden, so this event
   * triggers when the user clicks on the image of the upload button
   */
  const onClickHandler = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  /**
   * Checks if the image uploaded is valid based on its type and size.
   * @param file image file
   * @returns true if valid, false otherwise
   */
  const isValidFileUploaded = (file: File) => {
    // Check if file type is valid
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];

    // Check if size is valid
    const fileSize = file.size / 1024;
    if (fileSize < MAX_FILE_SIZE) {
      setIsImageValidSize(true);
    } else {
      setIsImageValidSize(false);
    }

    return validExtensions.includes(fileExtension) && fileSize < MAX_FILE_SIZE;
  };

  /**
   * Reads the data of the uploaded image and sends it to the CourseInputForm if valid.
   * @param event Image upload change event
   */
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (isValidFileUploaded(file)) {
      setIsImageValid(true);
      setIsImageUploaded(true);
    } else {
      setIsImageValid(false);
      setIsImageUploaded(false);
      onFileUpload(null);
      return;
    }
    onFileUpload(file);
  };

  return (
    <div>
      <div className={classes.UploadPrompt}>
        <span>Upload Course Image*:</span>
        <img
          alt="Upload button"
          className={classes.UploadButton}
          src={UploadIcon}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
        />
        {isImageUploaded && (
          <img alt="Upload checkmark" className={classes.UploadButton} src={CheckmarkIcon} />
        )}
      </div>
      <input
        data-testid="image-uploader"
        type="file"
        id="upload_input"
        ref={hiddenFileInput}
        onChange={onChangeHandler}
        style={{ display: "none" }}
      />
      <div className={classes.ErrorText}>
        {!isImageValid && <b>Please upload a valid image.</b>}
        {!isImageValidSize && <b> File size can not exceed 800KB.</b>}
      </div>
    </div>
  );
}

export default ImageUpload;
