import React from "react";
import { Link } from "react-router-dom";
import styles from "./SearchResultComponent.module.scss";
import filledStar from "../../assets/images/filled_star.png";
import emptyStar from "../../assets/images/empty_star.png";
import commentIcon from "../../assets/comment_icon.svg";

interface SearchResultComponentProp {
  CourseKey: string;
  CourseTitle: string;
  CourseImage: string;
  CourseRating: number;
  CoursePlatform: string;
  CourseCost: number;
  CourseDuration: number;
  CourseCategories: string[];
  CourseReview: string;
}

/**
 * Individual course view of SearchResultComponent
 */
function SearchResultComponent({
  CourseKey,
  CourseTitle,
  CourseImage,
  CourseRating,
  CoursePlatform,
  CourseCost,
  CourseDuration,
  CourseCategories,
  CourseReview,
}: SearchResultComponentProp) {
  const stringTruncationSize = 304;

  return (
    <div className={styles.search_result}>
      <img className={styles.search_result_image} src={CourseImage} alt="Course Thumbnail" />
      <div className={styles.search_result_title_section}>
        <h1 className={styles.search_result_title}>{CourseTitle}</h1>
        <div className={styles.search_result_stars}>
          {[...Array(5)].map((e, i) => {
            if (i < CourseRating) {
              return <img src={filledStar} alt="filledStar" />;
            }
            return <img src={emptyStar} alt="emptyStar" />;
          })}
        </div>
        <h2 className={styles.search_result_subtitle}>
          Platform:&nbsp;
          {CoursePlatform}
        </h2>
        <h2 className={styles.search_result_subtitle}>
          Cost:{" "}
          {CourseCost === 0
            ? "Free"
            : CourseCost === -1
            ? "Not Reported"
            : `$${CourseCost.toFixed(2)}`}
        </h2>
        <h2 className={styles.search_result_subtitle}>
          Duration:&nbsp;
          {CourseDuration} hours
        </h2>
        <div className={styles.search_result_category_section}>
          {CourseCategories.map((category) => (
            <span className={styles.search_result_category} key={category}>
              {category}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.search_result_review_section}>
        <img src={commentIcon} className={styles.search_result_review_image} alt="" />
        {CourseReview == null ? (
          <p className={styles.search_result_review_paragraph}>No Reviews Have Been Made Yet.</p>
        ) : CourseReview.length > stringTruncationSize ? (
          <p className={styles.search_result_review_paragraph}>
            &quot;{CourseReview.substring(0, stringTruncationSize)}
            ...&quot;
            <Link to={`/course/${CourseKey}`}>more</Link>
          </p>
        ) : (
          <p className={styles.search_result_review_paragraph}>&quot;{CourseReview}&quot;</p>
        )}
      </div>
    </div>
  );
}

export default SearchResultComponent;
