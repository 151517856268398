import React, { useEffect } from "react";
import Hero from "./Hero/Hero";
import CourseCarousel from "./CourseCarousel/CourseCarousel";
import CourseCategories from "./CourseCategories/CourseCategories";
import Mixpanel from "../shared/MixPanelService";

/**
 * Home/Landing page of website filled with infrormation such as banner, carousel, and categories
 */
function Homepage() {
  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "HomePage",
    });
  });

  return (
    <div>
      <Hero />
      <CourseCarousel />
      <CourseCategories />
    </div>
  );
}

export default Homepage;
