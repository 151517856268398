import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../shared/Header/Header";
import Mixpanel from "../shared/MixPanelService";
import { OktaUserCoreID } from "../shared/OktaUserInfo";
import CourseDetails from "./CourseDetails/CourseDetails";
import ReviewInputForm from "./ReviewInputForm/ReviewInputForm";

/**
 * Page for users to create a review for a specific course.
 * @returns jsx for NewReviewPage
 */
function NewReviewPage() {
  const { courseKey, coreId } = useParams<Record<string, string>>();

  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "NewReviewPage",
    });
  }, []);

  return (
    <div>
      <Header heading="Submit Course Review" usesAclonicaFontHeading={false} />
      <CourseDetails courseKey={courseKey} />
      <ReviewInputForm courseKey={courseKey} reviewCoreId={!coreId ? OktaUserCoreID()! : coreId} />
    </div>
  );
}

export default NewReviewPage;
