/* eslint "react/no-array-index-key": off */
import React from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import styles from "./CourseCarouselCard.module.scss";
import filledStar from "../../../assets/images/filled_star.png";
import emptyStar from "../../../assets/images/empty_star.png";
import Mixpanel from "../../../shared/MixPanelService";

interface CourseCarouselCardProp {
  carousel_slide: string;
  course_id: string;
  firstName: string;
  lastName: string;
  image: string;
  title: string;
  review: string;
  rating: number;
}

function CourseCarouselCard({
  carousel_slide,
  course_id,
  firstName,
  lastName,
  image,
  title,
  review,
  rating,
}: CourseCarouselCardProp) {
  const stringTruncationSize: number = 250;

  return (
    <Link
      to={`/course/${course_id}`}
      className={styles.card_container}
      style={{ textDecoration: "none" }}
      onClick={() =>
        Mixpanel.track("Homepage - Carousel Click", {
          CarouselSlide: carousel_slide,
          Course: course_id,
        })
      }
    >
      <div className={styles.card_header}>
        <Avatar name={`${firstName} ${lastName}`} size="60" round />
        {lastName === "" && (
          <div>
            <h3>No review yet</h3>
          </div>
        )}
        {lastName !== "" && (
          <div>
            <h3>
              {firstName} {lastName}
            </h3>
            <h3>Wrote a review</h3>
          </div>
        )}
      </div>
      <img src={image} className={styles.card_image} alt="" />
      <h2 className={styles.card_title}>{title}</h2>
      <div className={styles.card_stars}>
        {[...Array(5)].map((e, i) => {
          if (i < rating) {
            return <img src={filledStar} alt="filledStar" key={i} />;
          }
          return <img src={emptyStar} alt="emptyStar" key={i} />;
        })}
      </div>
      {review.length > stringTruncationSize ? (
        <p className={styles.card_review}>
          &quot;{review.substring(0, stringTruncationSize)}
          ...&quot;
        </p>
      ) : review.length === 0 ? (
        <p className={styles.card_review}>No review written yet.</p>
      ) : (
        <p className={styles.card_review}>&quot;{review}&quot;</p>
      )}
      <span className={styles.card_link}>Continue Reading</span>
    </Link>
  );
}
export default CourseCarouselCard;
