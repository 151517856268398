import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Header from "../shared/Header/Header";
import { OktaUserCoreID } from "../shared/OktaUserInfo";
import styles from "./ManagerPage.module.scss";
import ic_arrow_sort from "../assets/ic_arrow_sort_down.svg";
import ic_list_arrow_down from "../assets/ic_list_arrow_down.svg";
import ic_list_arrow_right from "../assets/ic_list_arrow_right.svg";
import EmployeeInvestigation from "./EmployeeInvestigation/EmployeeInvestigation";
import config from "../config";
import Mixpanel from "../shared/MixPanelService";

interface ManagerReportInterface {
  core_id: string;
  first_name: string;
  last_name: string;
  ytd_course_total: number;
  ytd_hours_total: number;
  total_course_total: number;
  total_hours_total: number;
}

function ManagerPage() {
  const { authState, oktaAuth } = useOktaAuth();
  // Retrieving CoreId from Okta
  const oktaCoreID = OktaUserCoreID();
  // The Report that is being retrieved from the backend API that has all the information of the employee
  const [managerReport, setManagerReport] = useState<ManagerReportInterface[]>([]);
  // List of CoreID's which are being open and inpsected on the webpage
  const [inspectingEmployeeKeys, setInspectingEmployeeKeys] = useState<string[]>([]);
  // Whether Name was last in acending or decending order
  const [isReportedAcendinglySortedbyName, setIsReportSortedByName] = useState<boolean>(false);
  // Wheather YTD Hours Taken was last in acending or decending order
  const [isReportAcendinglySortedByYTDTotalHours, setIsReportAcendinglySortedByYTDTotalHours] =
    useState<boolean>(false);

  /**
   * Calls API's required to display information on page
   */
  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "ManagerPage",
    });

    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${oktaCoreID}/manager_report`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setManagerReport(data.data);
        });
    }
  }, [authState, oktaAuth, oktaCoreID]);

  /**
   *  Sorts the list of employees in the manager report alphabetical
   *  by employee's first name
   *    - Uses isReportedAcendinglySortedbyName to decipher if
   *      order should be sorted in acending or decending
   */
  function onSortReportByEmployeeName() {
    const sortedEmployees = isReportedAcendinglySortedbyName
      ? managerReport.sort((a, b) => a.last_name.localeCompare(b.last_name))
      : managerReport.sort((a, b) => b.last_name.localeCompare(a.last_name));
    setManagerReport(sortedEmployees);
    setIsReportSortedByName(!isReportedAcendinglySortedbyName);
  }

  /**
   *  Sorts the list of employees in the manager report by how many hours
   *  of courses they have taken in the past year
   *    - Uses isReportAcendinglySortedByYTDHoursTaken to decipher if
   *      order should be sorted in acending or decending
   */
  function onSortReportByYTDTotalHours() {
    const sortedEmployees = isReportAcendinglySortedByYTDTotalHours
      ? managerReport.sort((a, b) => {
          return b.ytd_hours_total - a.ytd_hours_total;
        })
      : managerReport.sort((a, b) => {
          return a.ytd_hours_total - b.ytd_hours_total;
        });
    setManagerReport(sortedEmployees);
    setIsReportAcendinglySortedByYTDTotalHours(!isReportAcendinglySortedByYTDTotalHours);
  }

  /**
   * Manages state which determines whether an employee's subtable if displayed or not
   *    - If employee subtable if already open this will change state to have it closed
   *    - And Vice Versa
   * @param key The Employee Key (CoreID) which we are changing state for
   */
  function onInspectEmployee(key: string) {
    if (
      managerReport[managerReport.findIndex((EmployeeKey) => EmployeeKey.core_id === key)]
        .total_course_total >= 1
    ) {
      const isEmployeeInspected: boolean = inspectingEmployeeKeys.indexOf(key) > -1;
      const inspectingEmployeeKeysAltered: string[] = isEmployeeInspected
        ? inspectingEmployeeKeys.filter((el) => el !== key)
        : [...inspectingEmployeeKeys, key];
      setInspectingEmployeeKeys(inspectingEmployeeKeysAltered);
      // Tracking Inspection of Emlpoyee
      if (isEmployeeInspected) {
        Mixpanel.track("ManagerPage - Employee Inspected", {
          EmployeeCoreId: key,
        });
      } else {
        Mixpanel.track("ManagerPage - Employee Uninspected", {
          EmployeeCoreId: key,
        });
      }
    }
  }

  return (
    <div>
      <Header heading="Employee TROVE Report" />
      <div className={styles.table}>
        <div className={styles.first_row}>
          <div />
          <div className={styles.label_sortable}>
            <p>Employee</p>
            <img
              src={ic_arrow_sort}
              alt="Sort By Employee Name"
              onClick={onSortReportByEmployeeName}
              className={!isReportedAcendinglySortedbyName ? styles.sort_image_rotated : ""}
            />
          </div>
          <div className={styles.label}>
            <p>
              YTD
              <br />
              Courses Taken
            </p>
          </div>
          <div className={styles.label_sortable}>
            <p>
              YTD
              <br />
              Total Hours
            </p>
            <img
              src={ic_arrow_sort}
              alt="Sort By YTD Total Hours"
              onClick={onSortReportByYTDTotalHours}
              className={!isReportAcendinglySortedByYTDTotalHours ? styles.sort_image_rotated : ""}
            />
          </div>
          <div className={styles.label}>
            <p>
              YTD
              <br />
              Reviews
            </p>
          </div>
          <div className={styles.label}>
            <p>
              Total
              <br />
              Courses Taken
            </p>
          </div>
          <div className={styles.label}>
            <p>
              Total
              <br />
              Hours
            </p>
          </div>
          <div className={styles.label}>
            <p>
              Total
              <br />
              Reviews
            </p>
          </div>
        </div>
        {managerReport.map((employeeReport) => (
          <div key={employeeReport.core_id} data-testid="employee_row">
            <div
              className={styles.employee_row}
              role="button"
              onClick={() => onInspectEmployee(employeeReport.core_id)}
            >
              {employeeReport.total_course_total >= 1 ? (
                inspectingEmployeeKeys.indexOf(employeeReport.core_id) > -1 ? (
                  <img src={ic_list_arrow_down} alt="employee inspection open" />
                ) : (
                  <img src={ic_list_arrow_right} alt="employee inspection closed" />
                )
              ) : (
                <div />
              )}
              <p className={styles.employee_name_column} data-testid="employee_name">
                {employeeReport.last_name}, {employeeReport.first_name}
              </p>
              <p data-testid="employee_ytd_courses_taken">{employeeReport.ytd_course_total}</p>
              <p data-testid="employee_ytd_total_hours">{employeeReport.ytd_hours_total}</p>
              <p data-testid="employee_ytd_reviews">{employeeReport.ytd_course_total}</p>
              <p data-testid="employee_total_courses_taken">{employeeReport.total_course_total}</p>
              <p data-testid="employee_total_hours">{employeeReport.total_hours_total}</p>
              <p data-testid="employee_total_reviews">{employeeReport.total_course_total}</p>
            </div>
            {inspectingEmployeeKeys.indexOf(employeeReport.core_id) > -1 && (
              <EmployeeInvestigation coreID={employeeReport.core_id} />
            )}
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManagerPage;
