import React, { useState, useEffect, ChangeEvent, FormEvent, MouseEvent } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import ImageUpload from "./ImageUpload/ImageUpload";
import classes from "./CourseInputForm.module.scss";
import config from "../../config";
import { PlatformInterface } from "../../shared/types";
import Mixpanel from "../../shared/MixPanelService";
import { OktaUserCoreID } from "../../shared/OktaUserInfo";

/**
 * Form for users to create a new course
 * @returns jsx for the CourseInputForm
 */
function CourseInputForm(props: { courseKey: string }) {
  const { authState, oktaAuth } = useOktaAuth();

  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState(0);
  const [platform, setPlatform] = useState("Select...");
  const [image, setImage] = useState<File | null>(null);
  const [addedDate, setAddedDate] = useState(new Date());
  const [cost, setCost] = useState(0);
  const [averageRating, setAverageRating] = useState(0);

  const [loadedPlatforms, setLoadedPlatforms] = useState<PlatformInterface[]>([]);
  const [promptAddCourse, setPromptAddCourse] = useState(false);
  const [newPlatform, setNewPlatform] = useState("");

  const [urlHasError, setUrlHasError] = useState(false);
  const [platformHasError, setPlatformHasError] = useState(false);
  const [titleHasError, setTitleHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [durationHasError, setDurationHasError] = useState(false);
  const [imageIsValid, setImageIsValid] = useState(false);

  const [formSuccess, setFormSuccess] = useState(false);
  const [formFailure, setFormFailure] = useState(false);
  const [isUpdateAuthorized, setIsUpdateAuthorized] = useState(true);

  const history = useHistory();
  const { courseKey } = props;
  const isUpdatingCourse = courseKey !== undefined;
  const coreID = OktaUserCoreID() as string;

  /**
   * Load course platforms for dropdown from the server and store them in loadedPlatforms.
   * Also stores course information for updates.
   */
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      // Getting all platforms from database
      fetch(`${config.resourceServer}/platform`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          const platforms = data.data;
          platforms.push({ name: "Add course platform ..." });
          setLoadedPlatforms(platforms);
        });

      if (isUpdatingCourse) {
        fetch(`${config.resourceServer}/course/${courseKey}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
        })
          .then((response) => response.json())
          .then((data) => {
            // Check if the user is an admin
            let isAdmin = false;
            fetch(`${config.resourceServer}/admin/${coreID}`, {
              method: "GET",
              headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
            })
              .then((response) => response.json())
              .then((adminData) => {
                if (adminData.message !== "User is not an admin") {
                  isAdmin = true;
                }

                // If user is not the creator of the course or is not an admin,
                // prevent the user from updating the course
                if (coreID !== data.data.course_creator && !isAdmin) {
                  setIsUpdateAuthorized(false);
                } else {
                  setDuration(data.data.duration_hours);
                  setPlatform(data.data.platform_name);
                  setImageIsValid(true);
                  setAddedDate(data.data.added_date);
                  setCost(data.data.cost);
                  setAverageRating(data.data.average_rating);
                  setDescription(data.data.description);
                  setTitle(data.data.title);
                  setUrl(data.data.url);
                }
              });
          });
      }
    }
  }, []);

  /**
   * Checks if value is a URL. Requires https:// to be part of the URL.
   * @param value
   * @returns true if a URL, false otherwise
   */
  const isUrl = (value: string) => {
    let testUrl;
    try {
      testUrl = new URL(value);
    } catch (_) {
      return false;
    }

    return testUrl.protocol === "http:" || testUrl.protocol === "https:";
  };

  /**
   * Takes image from ImageUpload and mark the input as valid.
   * @param {File | null} uploadedImage
   */
  const fileUploadHandler = (uploadedImage: File | null) => {
    if (uploadedImage !== null) {
      setImage(uploadedImage);
      setImageIsValid(true);
    } else {
      setImageIsValid(false);
    }
  };

  /**
   * Show Add Course prompt if user wants to create a new platform and set
   * the coursePlatformValue with the chosen platform
   * @param selectedPlatform Chosen platform name
   */
  const coursePlatformSelectHandler = (selectedPlatform: string) => {
    if (selectedPlatform === "Add course platform ...") {
      setPlatform("");
      setPromptAddCourse(true);
    } else {
      setPlatform(selectedPlatform);
      setPromptAddCourse(false);
      setPlatformHasError(false);
    }
  };

  /**
   * Update value displayed on dropdown input when a user is entering a new course
   * @param event ChangeEvent from user typing the new course's name
   */
  const coursePlatformChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPlatform(event.target.value);
  };

  // Check if Add Platform form is ready to be submitted
  let platformFormIsValid = false;
  if (newPlatform.trim() !== "") {
    platformFormIsValid = true;
  }

  /**
   * Update course platform value and reset input once a new platform is submitted
   * @param event MouseEvent from user clicking on the course in the dropdown
   */
  const coursePlatformSubmitHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newCourse = (document.getElementById("platform_input") as HTMLInputElement).value;
    coursePlatformSelectHandler(newCourse);
    setNewPlatform("");
  };

  // Check if entire form is ready to be submitted once all inputs are valid
  let formIsValid = false;
  if (
    isUrl(url) &&
    platform !== "" &&
    platform !== "Select..." &&
    title !== "" &&
    description !== "" &&
    duration !== 0 &&
    imageIsValid
  ) {
    formIsValid = true;
  }

  /**
   * Once form is submitted, send data to server to create course and/or platform
   * @param event FormEvent from form submission
   */
  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    /* Creating a course */
    if (!isUpdatingCourse) {
      fetch(`${config.resourceServer}/course`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify({
          platform_name: platform,
          title,
          url,
          description,
          duration_hours: duration,
          added_date: new Date(),
          cost: -1,
          average_rating: -1,
          course_creator: coreID,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          fetch(data.data.image_blob_name, {
            method: "PUT",
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": image!.type,
            },
            body: image,
          }).then((blobResponse) => {
            if (blobResponse.ok) {
              setFormSuccess(true);
              const courseId = data.data.id;
              // Tracking New Course Creation
              Mixpanel.track("NewReviewPage - Created Course", {
                Course: courseId,
              });
              // If we're creating, redirect to New Review page
              history.push(`/course/${courseId}/review`);
            } else {
              setFormFailure(true);
            }
          });
        });
    } else {
      /* Updating a course */
      fetch(`${config.resourceServer}/course/${courseKey}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify({
          id: courseKey,
          platform_name: platform,
          title,
          url,
          description,
          duration_hours: duration,
          added_date: addedDate,
          cost,
          average_rating: averageRating,
          course_creator: coreID,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let responseOk = data.status === 200;
          // Only upload an image if user updates it
          if (image !== null) {
            fetch(data.data.image_blob_name, {
              method: "PUT",
              headers: {
                "x-ms-blob-type": "BlockBlob",
                "Content-Type": image!.type,
              },
              body: image,
            }).then((blobResponse) => {
              responseOk = blobResponse.ok;
            });
          }
          if (responseOk) {
            setFormSuccess(true);
            /* If we're updating, redirect to the Course Details page */
            if (isUpdatingCourse) {
              // Tracking New Course Update
              Mixpanel.track("NewReviewPage - Updated Course", {
                Course: courseKey,
              });
              history.push(`/course/${courseKey}`);
            }
          } else {
            setFormFailure(true);
          }
        });
    }
  };

  return (
    <div>
      {isUpdateAuthorized ? (
        <div>
          {formSuccess && (
            <div className={classes.FormSuccess}>
              <span>Successfully created! Redirecting to Review page...</span>
            </div>
          )}

          {formFailure && (
            <div className={classes.FormFailure}>
              <span>An error occurred while creating the course. Please try again.</span>
            </div>
          )}

          <form onSubmit={formSubmitHandler}>
            <div>
              <div className={classes.InputBox}>
                <label htmlFor="url">
                  New Course URL:
                  <input
                    type="text"
                    id="url"
                    placeholder="Enter URL*"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    onBlur={(e) => setUrlHasError(!isUrl(e.target.value))}
                  />
                </label>

                {urlHasError && (
                  <b className={classes.ErrorText}>
                    Please enter a valid course URL starting with &quot;https://&quot;.
                  </b>
                )}
              </div>

              <div className={classes.InputBox}>
                <span>Course Platform:</span>
                <div data-testid="platform-dropdown-component">
                  <Select
                    /* Map the course platform names to display in the dropdown */
                    options={loadedPlatforms.map((currPlatform: PlatformInterface) => ({
                      value: currPlatform.name,
                      label: currPlatform.name,
                    }))}
                    /* Handle the selected platform */
                    onChange={(chosenPlatform: SingleValue<{ label: string; value: string }>) =>
                      coursePlatformSelectHandler(chosenPlatform!.label)
                    }
                    /* Input is invalid if user does not select a platform */
                    onBlur={() => setPlatformHasError(platform === "Select..." || platform === "")}
                    /* Value is the platform that is displayed in the input box of the dropdown */
                    value={{ label: platform, value: platform }}
                  />
                </div>
                <div>
                  {/* Popup to add a new platform */}
                  {promptAddCourse && (
                    <div className={classes.PlatformInput}>
                      <label htmlFor="platform_input">
                        Enter Course Platform
                        <input
                          id="platform_input"
                          data-testid="platform_input"
                          type="text"
                          placeholder="Course platform name..."
                          onChange={coursePlatformChangeHandler}
                        />
                        <button
                          type="button"
                          data-testid="platform_input_button"
                          onClick={coursePlatformSubmitHandler}
                          disabled={!platformFormIsValid}
                        >
                          Submit
                        </button>
                      </label>
                    </div>
                  )}
                </div>

                {platformHasError && (
                  <b className={classes.ErrorText}>Please select or enter a platform.</b>
                )}
              </div>

              <div className={classes.InputBox}>
                <label htmlFor="title">
                  Course Title:
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter Course Title*"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={(e) => setTitleHasError(e.target.value.trim() === "")}
                  />
                </label>

                {titleHasError && <b className={classes.ErrorText}>Please enter a course title.</b>}
              </div>

              <div className={classes.InputBox}>
                <label htmlFor="description">
                  Course Description:
                  <input
                    type="text"
                    id="description"
                    placeholder="Paste Course Description From Course Site*"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={(e) => setDescriptionHasError(e.target.value.trim() === "")}
                  />
                </label>

                {descriptionHasError && (
                  <b className={classes.ErrorText}>Please enter a course description.</b>
                )}
              </div>

              <div className={classes.InputBox}>
                <label htmlFor="duration">
                  Course Duration (in hours):
                  <input
                    type="number"
                    id="duration"
                    placeholder="Enter Course Duration in Hours*"
                    value={duration}
                    onChange={(e) => setDuration(parseFloat(e.target.value))}
                    onBlur={(e) => setDurationHasError(e.target.value === "0")}
                  />
                </label>

                {durationHasError && (
                  <b className={classes.ErrorText}>Please enter a numeric course duration.</b>
                )}
              </div>

              {isUpdatingCourse && (
                <div className={classes.ImageMessage}>Update image, if needed:</div>
              )}

              <div className={classes.ImageUploadButton}>
                <ImageUpload
                  isRefreshed={formSuccess || formFailure}
                  onFileUpload={fileUploadHandler}
                />
              </div>

              <div className={classes.SubmitButton}>
                <button type="submit" disabled={!formIsValid}>
                  {isUpdatingCourse
                    ? "Finish Updating Course and Submit"
                    : "Submit Course to Inventory and Enter Review"}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <h2 className={classes.not_authorized}>You are not authorized to update this course!</h2>
      )}
    </div>
  );
}

export default CourseInputForm;
