let resourceServer: string = "";
let mixpanelProjectToken: string | undefined;

if (window.location.hostname === "localhost") {
  resourceServer = "http://localhost:1323/v1";
} else if (window.location.origin === "https://trove.dev.commandcentral.com") {
  resourceServer = "https://trove-api.dev.commandcentral.com/v1";
  mixpanelProjectToken = "4faed101556c6af6009930bc562d5eb4";
} else if (window.location.origin === "https://trove.stage.commandcentral.com") {
  resourceServer = "https://trove-api.stage.commandcentral.com/v1";
  mixpanelProjectToken = "97a85b82a06ad8eeec8736bacc6f737a";
} else if (
  window.location.origin === "https://trove.commandcentral.com" ||
  window.location.origin === "https://trove.motorolasolutions.com"
) {
  resourceServer = "https://trove-api.commandcentral.com/v1";
  mixpanelProjectToken = "643f6f472da091d1386ffba8a2e067b9";
}

export default {
  oidc: {
    clientId: process.env.OKTA_CLIENT_ID || "0oanyw18kndt1iYms1t7",
    issuer: process.env.OKTA_ISSUER || "https://motorolasolutions.okta.com/",
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false,
  },
  resourceServer,
  localServer: "http://localhost:1323/v1",
  mixpanelProjectToken,
};
