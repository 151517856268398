import React, { useState, ChangeEvent, FocusEvent, useEffect } from "react";
import classes from "./ReviewInputBox.module.scss";

/**
 * Growing input box for a user's review of a course.
 * @param props onChangeReview handler
 * @returns jsx for ReviewInputBox
 */
function ReviewInputBox(props: {
  initialReview: string;
  onChangeReview: (review: string) => void;
}) {
  // The number of default rows displayed in the text area
  const DEFAULT_TEXT_AREA_HEIGHT = 3;

  // Size in pixels of each line of text. Used to calculate the new size of the text area.
  const ROW_HEIGHT = 22;

  const [textAreaHeight, setTextAreaHeight] = useState(DEFAULT_TEXT_AREA_HEIGHT);
  const [inputHasError, setInputHasError] = useState(false);
  const [review, setReview] = useState("");
  const { initialReview, onChangeReview } = props;

  useEffect(() => {
    setReview(initialReview);
  }, [initialReview]);

  /**
   * Calculates the new height of the text area with each change, checks if user has entered a value,
   * and sends the value to props.
   * @param event ChangeEvent from typing in the input box
   */
  const reviewChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    /* Height of text in pixels */
    const height = event.target.scrollHeight;

    /* Divide height by ROW_HEIGHT to get number of rows of text */
    const newRows = Math.ceil(height / ROW_HEIGHT) - 1;

    if (newRows > textAreaHeight) {
      setTextAreaHeight(newRows);
    }
    if (event.target.value !== "") {
      setInputHasError(false);
    }

    /* Get the current character count to display on the screen */
    document.getElementById("current_count")!.textContent = event.target.value.length.toString();

    /* Pass back the value of the review to the form */
    onChangeReview(event.target.value);
    setReview(event.target.value);
  };

  /**
   * Marks the input as having an error if user has not entered a value after clicking out of the text area.
   * @param event FocusEvent
   */
  const reviewBlurHandler = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (event.target.value === "") {
      setInputHasError(true);
    } else {
      setInputHasError(false);
    }
  };

  return (
    <div className={classes.InputBox}>
      <textarea
        className={classes.review_input}
        data-testid="review_input"
        rows={textAreaHeight}
        value={review}
        onChange={reviewChangeHandler}
        placeholder="This course was..."
        onBlur={reviewBlurHandler}
        maxLength={800}
      />
      <div className={classes.CharacterCount} data-testid="test-span">
        <span id="current_count">0</span>
        <span id="max_count"> / 800</span>
      </div>
      {inputHasError && <b className={classes.ErrorText}>Please enter a review.</b>}
    </div>
  );
}

export default ReviewInputBox;
