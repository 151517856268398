/* eslint "react/no-array-index-key": off */
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import styles from "./EmployeeInvestigation.module.scss";
import ic_arrow_sort from "../../assets/ic_arrow_sort_down.svg";
import ic_star_filled from "../../assets/ic_star_filled.svg";
import ic_star_empty from "../../assets/ic_star_empty.svg";
import config from "../../config";

interface EmployeeCoursesModel {
  course_id: number;
  title: string;
  platform_name: string;
  duration_hours: number;
  created_date: string;
  rating: number;
}

interface EmployeeInvestigationProp {
  coreID: string;
}

function EmployeeInvestigation({ coreID }: EmployeeInvestigationProp) {
  const { authState, oktaAuth } = useOktaAuth();
  const [employeeCourses, setEmployeeCourses] = useState<EmployeeCoursesModel[]>([]);
  const [isDateAssending, setIsDateAssending] = useState<boolean>(true);

  /**
   * Calls API's required to display information on page
   */
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${coreID}/review`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setEmployeeCourses(data.data);
        });
    }
  }, [authState, oktaAuth, coreID]);

  /**
   * Sorts the list of employees in the manager report by the date they
   * completed/reviewed the course.
   *    - Also changes state so we can properly display the sorting order
   */
  const onSortByDate = () => {
    if (isDateAssending) {
      setEmployeeCourses(
        employeeCourses.sort((a, b) => {
          return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
        }),
      );
      setIsDateAssending(false);
    } else {
      setEmployeeCourses(
        employeeCourses.sort((a, b) => {
          return new Date(a.created_date).getTime() - new Date(b.created_date).getTime();
        }),
      );
      setIsDateAssending(true);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.first_row}>
        <div className={styles.label_sortable} onClick={onSortByDate} role="button">
          <p>Date Completed</p>
          {isDateAssending ? (
            <img src={ic_arrow_sort} alt="" />
          ) : (
            <img src={ic_arrow_sort} alt="" className={styles.down_arrow} />
          )}
        </div>
        <div className={styles.label}>
          <p>Course Name</p>
        </div>
        <div className={styles.label}>
          <p>Platform</p>
        </div>
        <div className={styles.label}>
          <p>Hours</p>
        </div>
        <div className={styles.label}>
          <p>Review</p>
        </div>
      </div>
      {employeeCourses ? (
        employeeCourses.map((course) => (
          <div className={styles.course_row} key={course.course_id} data-testid="course_row">
            <p>
              {new Date(course.created_date).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
            </p>
            <Link to={`/course/${course.course_id}`} className={styles.item_course_URL}>
              {course.title}
            </Link>
            <p>{course.platform_name}</p>
            <p>{course.duration_hours}</p>
            <div className={styles.stars_flex}>
              {[...Array(5)].map((e, i) => {
                if (i < course.rating) {
                  return <img src={ic_star_filled} alt="filledStar" key={i} />;
                }
                return <img src={ic_star_empty} alt="emptyStar" key={i} />;
              })}
            </div>
          </div>
        ))
      ) : (
        <div />
      )}
    </div>
  );
}

export default EmployeeInvestigation;
