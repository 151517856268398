import React, { useCallback, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import myWishlistStyles from "./MyWishlistComponent.module.scss";
import config from "../../config";
import { OktaUserCoreID } from "../../shared/OktaUserInfo";
import TrashIcon from "../../assets/images/ic_trash.svg";

interface CourseWishlist {
  employee_core_id: string;
  course_id: number;
  added_date: string;
  title: string;
  image_blob_name: string;
}

/**
 * Render content of My Wishlist Tab here. Includes user's wishlist of courses with their image, course description and the date when it was added.
 * @returns
 */
function MyWishlistTab() {
  const { authState, oktaAuth } = useOktaAuth();
  const coreID = OktaUserCoreID();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedWishlists, setLoadedWishlists] = useState<CourseWishlist[]>([]);

  /**
   * Calls ResourceApi to get information regarding a user's wishlist
   *    - Temporarily will have page display loading message while calling API
   */
  const getWishlist = useCallback(() => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${coreID}/wish`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoadedWishlists(data.data);
          setIsLoading(false);
        });
    }
  }, [coreID, oktaAuth, authState]);

  /**
   * On Click of a Course's delte button this function will call the resourse server to delete appropriate course
   * @param key key of the course which is desired to be deleted
   */
  const deleteCourseHandler = (course_id: number): void => {
    fetch(`${config.resourceServer}/employee/${coreID}/course/${course_id}/wish`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        getWishlist();
      });
  };

  /* Hook for fetching courses from the backend API. */
  useEffect(() => {
    getWishlist();
    setIsLoading(false);
  }, [getWishlist]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={myWishlistStyles.wishlist_grid}>
      {/* check if array before calling `map()` */}
      {Array.isArray(loadedWishlists) && loadedWishlists.length !== 0 ? (
        loadedWishlists.map((course) => (
          <div>
            <Link to={`/course/${course.course_id}`} style={{ textDecoration: "none" }}>
              <img
                className={myWishlistStyles.wishlist_item_image}
                src={course.image_blob_name}
                alt="coursePic"
              />
            </Link>

            {/* Includes description of the course with name, URL and the date it was added. */}
            <div className={myWishlistStyles.wishlist_item_description}>
              <div>
                <Link to={`/course/${course.course_id}`} style={{ textDecoration: "none" }}>
                  <h4 className={myWishlistStyles.course_link}>{course.title}</h4>
                </Link>
                <h4 className={myWishlistStyles.wishlist_item_date}>
                  Added:{" "}
                  {new Date(course.added_date).toLocaleDateString("en-US", {
                    timeZone: "UTC",
                  })}
                </h4>
              </div>
              {/* Delete button and its functionality */}
              <img
                src={TrashIcon}
                alt="trashCan"
                className={myWishlistStyles.trash_icon}
                onClick={() => deleteCourseHandler(course.course_id)}
              />
            </div>
          </div>
        ))
      ) : (
        /* can also return "null" instead of statement - outputs when there are no entries in the wishlist */
        <div className={myWishlistStyles.wishlist_items_none}>
          You have not added any courses to your wishlist.
        </div>
      )}
    </div>
  );
}
export default MyWishlistTab;
