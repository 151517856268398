import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LeftSquiggle from "../../assets/squiggleLeft.png";
import RightSquiggle from "../../assets/squiggleRight.png";
import Mixpanel from "../../shared/MixPanelService";
import NavMenu from "../../shared/NavMenu/NavMenu";
import styles from "./Hero.module.scss";

/**
 * Homepage Hero which is the top banner of the homepage
 * @returns top banner for homepage
 */
function Hero() {
  const history = useHistory();
  const [input, setInput] = useState<string>("");

  /**
   * Function that runs when searches for a course
   * - Navigates to SearchResult page utilizing the form input as a queryParam
   * - Encodes queryParams so special characters such as -./, and more can be used
   */
  const navigateToCourseSearchPage = () => {
    Mixpanel.track("Homepage - Search Bar Used", {
      Search: input,
    });
    history.push(`/course/search?courseSearch=${encodeURIComponent(input)}`);
  };

  return (
    <div className={styles.trove__header__background}>
      <div className={styles.trove__header__hamburger}>
        <NavMenu hasWhiteIcon />
      </div>

      <div className={styles.trove__header__centerContent}>
        <div className={styles.trove__header__title}>
          <img className={styles.trove_header__img} src={LeftSquiggle} alt="" />
          <div className={styles.trove_header__text1}>TROVE</div>
          <img className={styles.trove_header__img} src={RightSquiggle} alt="" />
        </div>

        <div className={styles.trove__header__subHeader}>
          MSI&apos;S CROWD-SOURCED LEARNING PORTAL
        </div>
      </div>

      <div className={styles.trove__header__search}>
        <form className={styles.input_wrapper} onSubmit={navigateToCourseSearchPage}>
          <button className={styles.search_bar_button} type="submit" />
          <input
            type="text"
            placeholder="Search by topic, course name or learning platform. *If you want to add a course, please search for it first."
            value={input}
            onChange={(event) => setInput(event.target.value)}
          />
        </form>
      </div>
    </div>
  );
}

export default Hero;
