import React from "react";
import { Link } from "react-router-dom";
import styles from "./CourseCategories.module.scss";
import SoftwareDev from "../../assets/software-development 1.png";
import Business from "../../assets/PinClipart 1.png";
import PersonalDev from "../../assets/personal-development 1.png";
import Emerging from "../../assets/emerge.png";
import UXDesign from "../../assets/Seek.png";
import TheArts from "../../assets/arts.png";
import Engineering from "../../assets/engineering.png";
import LeaderShip from "../../assets/leadership.png";
import MSIInternal from "../../assets/msi-internal.png";
import Mixpanel from "../../shared/MixPanelService";

/**
 * Bottom section of homepage containing all the suggested categories to search by
 * @returns Course Categories section of homepage
 */
function CourseCategories() {
  return (
    <div className={styles["category-section"]}>
      <div className={styles["category-section__header"]}>BROWSE BY CATEGORY</div>
      <div className={styles["category-section__container"]}>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Software Development")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Software Development",
            })
          }
        >
          <img src={SoftwareDev} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Software Development</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Business")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Business",
            })
          }
        >
          <img src={Business} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Business</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Personal Development")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Personal Development",
            })
          }
        >
          <img src={PersonalDev} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Personal Development</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Emerging Tech")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Emerging Tech",
            })
          }
        >
          <img src={Emerging} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Emerging Tech</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("UX/Design")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "UX/Design",
            })
          }
        >
          <img src={UXDesign} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>UX / Design</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("The Arts")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "The Arts",
            })
          }
        >
          <img src={TheArts} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>The Arts</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Engineering")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Engineering",
            })
          }
        >
          <img src={Engineering} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Engineering</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("Leadership")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "Leadership",
            })
          }
        >
          <img src={LeaderShip} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>Leadership</div>
        </Link>
        <Link
          className={styles["category-section__card"]}
          to={`/course/search?category=${encodeURIComponent("MSI Internal")}`}
          onClick={() =>
            Mixpanel.track("Homepage - Categories Click", {
              Category: "MSI Internal",
            })
          }
        >
          <img src={MSIInternal} className={styles["category-section__img"]} alt="" />
          <div className={styles["category-section__card-content"]}>MSI Internal</div>
        </Link>
      </div>
    </div>
  );
}

export default CourseCategories;
