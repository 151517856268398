import React from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./NavMenu.module.scss";
import Mixpanel from "../MixPanelService";

interface NavMenuInterface {
  hasWhiteIcon?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
NavMenu.defaultProps = {
  hasWhiteIcon: false,
};

/*
 *  Serves as the dropdown menu - when hovered over, it displays options that
 *  will allow the user to navigate.
 */
function NavMenu({ hasWhiteIcon }: NavMenuInterface) {
  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => {
    Mixpanel.track("NavMenu Used", {
      NavTo: "Login",
    });
    oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    Mixpanel.track("NavMenu Used", {
      NavTo: "Logout",
    });
    oktaAuth.signOut();
    Mixpanel.track("Successful logout");
    Mixpanel.reset();
  };

  return (
    <div className={styles.dropDown}>
      <button className={styles.dropBtn} type="button">
        {hasWhiteIcon ? (
          <span className={styles["hamburger-white"]} />
        ) : (
          <span className={styles["hamburger-black"]} />
        )}
      </button>
      <div className={styles.dropDownContent}>
        <Link
          to="/"
          className={styles.link}
          onClick={() => {
            Mixpanel.track("NavMenu Used", {
              NavTo: "Home",
            });
          }}
        >
          Home{" "}
        </Link>
        <Link
          to="/profile?tab=myCoursesTab"
          className={styles.link}
          onClick={() => {
            Mixpanel.track("NavMenu Used", {
              NavTo: "My Trove",
            });
          }}
        >
          My Trove
        </Link>
        <Link
          to="/reports"
          className={styles.link}
          onClick={() => {
            Mixpanel.track("NavMenu Used", {
              NavTo: "Reports",
            });
          }}
        >
          Reports (Mgr Only)
        </Link>
        <Link
          to="/admin"
          className={styles.link}
          onClick={() => {
            Mixpanel.track("NavMenu Used", {
              NavTo: "Admin",
            });
          }}
        >
          Admin
        </Link>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdq4m2GZ1_96jsVgHMAIp9dkrMqlweHukMZUFJDTGl-Txt9dQ/viewform?usp=sf_link"
          className={styles.link}
          type="button"
          onClick={() => {
            Mixpanel.track("NavMenu Used", {
              NavTo: "Report Bug",
            });
          }}
        >
          Report Bug
        </a>
        {authState && authState.isAuthenticated && (
          // eslint-disable-next-line
          <a onClick={logout} className={styles.link} type="button">
            Logout
          </a>
        )}
        {authState && !authState.isAuthenticated && (
          // eslint-disable-next-line
          <a onClick={login} className={styles.link} type="button">
            Login
          </a>
        )}
      </div>
    </div>
  );
}

export default NavMenu;
