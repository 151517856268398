import React, { useCallback, Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import classes from "./ShareButtonModal.module.scss";

interface ShareButtonModalProp {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

function ShareButtonModal({ setIsModalOpen }: ShareButtonModalProp) {
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `I found this course on Trove, Motorola Solutions crowd-sourced learning portal, and thought you would be interested! ${window.location.href}`,
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      contentLabel="Share Modal"
      className={classes.PopupWindow}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
      parentSelector={() => document.body}
    >
      <b>Share Course</b> (Copy/Paste into Email or Chat)
      <hr />
      <div>
        <p>
          I found this course on Trove, Motorola Solutions&apos; crowd-sourced learning portal, and
          thought you would be interested!
          <br />
          <a href={window.location.href} target="_blank" rel="noreferrer">
            {window.location.href}
          </a>
        </p>
        <button onClick={copy} className={classes.copy} type="button">
          Copy
        </button>
      </div>
    </Modal>
  );
}

export default ShareButtonModal;
