import React, { useState } from "react";
import Modal from "react-modal";
import classes from "./GuidelinesPopUp.module.scss";

/**
 * Modal popup to display the review guidelines.
 * @returns jsx for GuidelinesPopUp
 */
function GuidelinesPopUp() {
  const [modalIsOpen, setIsOpen] = useState(false);

  /**
   * Opens the modal when the popup button is clicked.
   */
  function openModal() {
    setIsOpen(true);
  }

  /**
   * Closes the modal when the 'X' is clicked.
   */
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button type="button" onClick={openModal} className={classes.GuidelinesButton}>
        Read Review Guidelines
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Review Guidelines Modal"
        className={classes.PopupWindow}
        overlayClassName={classes.Overlay}
        ariaHideApp={false}
      >
        <button className={classes.CloseButton} type="button" onClick={closeModal}>
          &times;
        </button>
        <h2>Trove Standards for Ratings & Reviews</h2>
        <hr />
        <div>
          <p>
            The presence of employee-generated reviews on Trove is intended to benefit the employees
            of Motorola Solutions. The goal of the app is to help you find the most effective
            web-based training and to give you the opportunity to help others in their search for
            the best training across a wide variety of web-based education platforms.
          </p>
          <br />
          <p>
            Please refer to the guidelines below when submitting a review and when flagging a review
            as inappropriate:
          </p>
          <br />
          <p>
            <b>Reviews should be Authentic</b>
            <br />
            Trove reviews should only be submitted by those who have actually taken the course they
            are reviewing.
          </p>
          <br />
          <p>
            <b>Reviews should be Helpful</b>
            <br />
            The best reviews include not only how you felt about the course but also why.
          </p>
          <br />
          <p>
            <b>Reviews should be Relevant</b>
            <br />
            The best reviews are focused on relevant topics like content, delivery, presenter style,
            effectiveness, exercises and examples.
          </p>
          <br />
          <p>
            <b>Reviews should be Appropriate</b>
            <br />
            Reviews that are offensive, obscene, or malicious or that reference illegal activity or
            conditions will be removed and should be flagged as inappropriate by any Trove user that
            encounters such a review.
          </p>
          <br />
          <p>
            *Administrators reserve the right to modify reviews to conform with the published review
            guidelines.
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default GuidelinesPopUp;
