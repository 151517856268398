import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import NewCoursePage from "./NewCoursePage/NewCoursePage";
import config from "./config";
import HomePage from "./HomePage/HomePage";
import MyTrovePage from "./MyTrovePage/MyTrovePage";
import NewReviewPage from "./NewReviewPage/NewReviewPage";
import "./App.scss";
import CourseDetailPage from "./CourseDetailPage/CourseDetailPage";
import SearchResultsPage from "./SearchResultsPage/SearchResultsPage";
import ManagerPage from "./ManagerPage/ManagerPage";
import AdminPage from "./AdminPage/AdminPage";
import Mixpanel from "./shared/MixPanelService";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();

  /**
   * Templated code from Okta Import Documentation to sign-users
   * @param oktaAuthResponse
   * @param originalUri
   */
  const restoreOriginalUri = async (oktaAuthResponse: OktaAuth, originalUri: string) => {
    // When okta sends response back redirect webpage back to originalUri
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    // When okta user signs in we send a api response to with
    oktaAuthResponse.getUser().then((userInfo: any) => {
      // Making a variable for core_id since it will be used quite often
      const userCoreId = userInfo.preferred_username.substring(
        0,
        userInfo.preferred_username.indexOf("@"),
      );
      // Creating or Updating Employee in backend with correct user info
      fetch(`${config.resourceServer}/employee`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
        body: JSON.stringify({
          core_id: userCoreId,
          first_name: userInfo.given_name,
          last_name: userInfo.family_name,
          email: userInfo.email,
          manager_id: userInfo.managerid,
        }),
      });
      // Identifying them in Mixpanel and setting user info
      Mixpanel.identify(userCoreId);
      Mixpanel.track("Successful login");
      Mixpanel.people.set({
        $core_id: userCoreId,
        $first_name: userInfo.given_namee,
        $last_name: userInfo.family_name,
        $email: userInfo.email,
        $manager_id: userInfo.managerid,
      });
    });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/" exact component={HomePage} />
        <SecureRoute path="/profile" component={MyTrovePage} />
        <SecureRoute path="/course/search" component={SearchResultsPage} />
        <SecureRoute path="/course/create" component={NewCoursePage} />
        <SecureRoute path="/course/:courseKey/update" component={NewCoursePage} />
        <SecureRoute path="/course/:courseKey/review" exact component={NewReviewPage} />
        <SecureRoute path="/course/:courseKey/review/:coreId" component={NewReviewPage} />
        <SecureRoute path="/course/:courseKey" exact component={CourseDetailPage} />
        <SecureRoute path="/reports" component={ManagerPage} />
        <SecureRoute path="/admin" component={AdminPage} />
      </Switch>
    </Security>
  );
}

export default App;
