import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./MyCoursesComponent.module.scss";
import filledStar from "../../assets/images/filled_star.png";
import emptyStar from "../../assets/images/empty_star.png";
import config from "../../config";

interface ReviewOfEmployeeInterface {
  course_id: number;
  title: string;
  platform_name: string;
  duration_hours: number;
  created_date: string;
  rating: number;
  helpfulCount: number;
  helpful: boolean;
  isAppropriate: boolean;
  inappropriateReporter: string;
}

/**
 * Render content in My Courses tab here. User can view the courses they took,
 * which platform it was hosted on, and their ratings.
 */
function MyCoursesTab() {
  const { authState, oktaAuth } = useOktaAuth();
  const coreIDEmail = useOktaAuth().authState?.idToken?.claims.preferred_username;
  const coreID = coreIDEmail?.substring(0, coreIDEmail.indexOf("@")) as string;

  const [isLoading, setIsLoading] = useState(true);
  const [loadedCourses, setLoadedCourses] = useState<ReviewOfEmployeeInterface[]>([]);

  /**
   * Calls ResourceApi to get information regarding a user's courses
   *    - Temporarily will have page display loading message while calling API
   */
  function getCourses(): void {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${coreID}/review`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoadedCourses(data.data);
        });
    }
  }

  /**
   * Hook for fetching courses from the backend API
   */
  useEffect(() => {
    setIsLoading(true);
    getCourses();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className={styles.courses_description}>
        Don&apos;t see a class you took?
        <div className={styles.search_link}>
          <Link to="/"> Search for the course and then click &quot;Get Credit&quot; </Link>
        </div>
      </div>
      <div className={styles.courses_container}>
        <table>
          <thead>
            <tr>
              <th>Course</th>
              <th>Platform</th>
              <th>Date Completed</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {/* Maps the loaded courses to the necessary information
            that must be displayed on the courses page. */}
            {Array.isArray(loadedCourses) && loadedCourses.length !== 0 ? (
              loadedCourses.map((course) => (
                <tr>
                  <td>
                    <Link to={`/course/${course.course_id}`} className={styles.item_course_URL}>
                      {course.title}
                    </Link>
                  </td>
                  <td>
                    <div className={styles.item_platform}>{course.platform_name}</div>
                  </td>
                  <td>
                    <div className={styles.item_date_reviewed}>
                      {new Date(course.created_date).toLocaleDateString("en-US", {
                        timeZone: "UTC",
                      })}
                    </div>
                  </td>
                  <td>
                    {/* Maps user's rating to filled stars and renders it as
                  colored star visuals. */}
                    <div className={styles.item_row_display}>
                      {[...Array(5)].map((e, i) => {
                        if (i < course.rating) {
                          return <img src={filledStar} alt="filledStar" />;
                        }
                        return <img src={emptyStar} alt="emptyStar" />;
                      })}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div> You have not taken any courses. </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default MyCoursesTab;
