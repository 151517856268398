import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CourseInputForm from "./CourseInputForm/CourseInputForm";
import Header from "../shared/Header/Header";
import Mixpanel from "../shared/MixPanelService";

/**
 * Page for users to submit a new course.
 * @returns jsx for NewCoursePage
 */
function NewCoursePage() {
  const { courseKey } = useParams<Record<string, string>>();

  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "NewCoursePage",
    });
  }, []);

  return (
    <div>
      <Header
        heading="Submit a New Course"
        usesAclonicaFontHeading={false}
        description="Use this form to submit a course you have taken and is not currently
        in the learning portal inventory. To maintain the integrity of the
        inventory, please do not submit courses you have not taken."
      />
      <CourseInputForm courseKey={courseKey} />
    </div>
  );
}

export default NewCoursePage;
