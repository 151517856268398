import React, { useState, useEffect } from "react";
import Select, { MultiValue } from "react-select";
import { useOktaAuth } from "@okta/okta-react";
import Header from "../shared/Header/Header";
import config from "../config";
import ReviewModal from "./ReviewModal/ReviewModal";
import { AdminInterface } from "../shared/types";
import styles from "./AdminPage.module.scss";
import ic_arrow_sort from "../assets/ic_arrow_sort_down.svg";
import Mixpanel from "../shared/MixPanelService";
import { OktaUserCoreID } from "../shared/OktaUserInfo";

type AdminOptions = {
  value: string;
  label: string;
};

interface InappropriateReviewInterface {
  employee_core_id: string;
  course_id: number;
  title: string;
  review: string;
}

function AdminPage() {
  const { authState, oktaAuth } = useOktaAuth();

  // Whether list of admins is ascendingly sorted or not
  const [isAcendinglySortedbyName, setIsSortedByName] = useState<boolean>(false);
  // List of current admins
  const [admins, setAdmins] = useState<AdminInterface[]>([]);
  // List of users that are not admins
  const [nonAdmins, setNonAdmins] = useState<AdminInterface[]>([]);
  // List of users the current user wants to add as an admin(s)
  const [adminsToAdd, setAdminsToAdd] = useState<AdminOptions[]>([]);
  // List of inappropriate content reports
  const [inappropriateReviews, setInappropriateReviews] = useState<InappropriateReviewInterface[]>(
    [],
  );
  // Whether or not the current user is an admin
  const [isAdmin, setIsAdmin] = useState(false);
  // Whether or not we need to update the page upon deletion/addition of an admin/report
  const [doesNeedRender, setDoesNeedRender] = useState<boolean>(false);
  const coreID = OktaUserCoreID() as string;

  /**
   * Calls APIs needed to display information on the page
   */
  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "AdminPage",
    });

    if (authState && authState.isAuthenticated) {
      // Check if the user is an admin
      fetch(`${config.resourceServer}/admin/${coreID}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((adminData) => {
          if (adminData.message === "User is not an admin") {
            setIsAdmin(false);
          } else {
            setIsAdmin(true);

            // Get all current admins
            fetch(`${config.resourceServer}/admin`, {
              method: "GET",
              headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.status === 200) {
                  setAdmins(data.data);
                }
              });

            // Get all non-admins for the dropdown
            fetch(`${config.resourceServer}/admin/nonAdmins`, {
              method: "GET",
              headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.status === 200) {
                  setNonAdmins(data.data);
                }
              });

            // Get all inappropriate reports
            fetch(`${config.resourceServer}/review/inappropriate`, {
              method: "GET",
              headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.status === 200) {
                  setInappropriateReviews(data.data);
                }
              });
          }
        });
    }
    setDoesNeedRender(false);
  }, [doesNeedRender]);

  /**
   *  Sorts the list of admins alphabetically by employee's last, first name
   *  Uses isAcendinglySortedbyName to decipher if
   *  order should be sorted in acending or decending
   */
  function onSortReportByEmployeeName() {
    const sortedEmployees = isAcendinglySortedbyName
      ? admins.sort((a, b) => a.last_name.localeCompare(b.last_name))
      : admins.sort((a, b) => b.last_name.localeCompare(a.last_name));
    setAdmins(sortedEmployees);
    setIsSortedByName(!isAcendinglySortedbyName);
  }

  /**
   * Removes a user as an admin. Also updates the list of current admins and non-admins.
   *
   * @param employee_core_id CoreID of admin to remove
   * @param first_name First name of admin to remove
   * @param last_name Last name of admin to remove
   */
  const removeAdminHandler = (employee_core_id: string, first_name: string, last_name: string) => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/admin/${employee_core_id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            setAdmins((admin) =>
              admin.filter((adminArray) => adminArray.employee_core_id !== employee_core_id),
            );
            setNonAdmins(
              [
                ...nonAdmins,
                {
                  employee_core_id,
                  first_name,
                  last_name,
                },
              ].sort((a, b) => a.last_name.localeCompare(b.last_name)),
            );
          }
        });
    }
  };

  /**
   * Updates the list of users we want to add as an admin, taken from selections from the dropdown.
   *
   * @param options The list of admins selected in the dropdown
   */
  const updateAdminListHandler = (options: MultiValue<{ value: string; label: string }>) => {
    const updatedOptions = new Array<AdminOptions>();
    Object.entries(options).forEach((obj) =>
      updatedOptions.push({ value: obj[1].value, label: obj[1].label }),
    );

    setAdminsToAdd(updatedOptions);
  };

  /**
   * Adds the admins from adminsToAdd to the Admin database table.
   */
  const addAdminHandler = () => {
    const adminCoreIds = adminsToAdd.map((e) => e.value);
    fetch(`${config.resourceServer}/admin/multiInserts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      },
      body: JSON.stringify({
        employee_core_id_list: adminCoreIds,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 201) {
          setAdminsToAdd([]);
          setDoesNeedRender(true);
        }
      });
  };

  return (
    <div>
      <Header heading="TROVE Administration" />
      {isAdmin ? (
        <div className={styles.admin_functions}>
          <div className={styles.admin_manage}>
            <p className={styles.section_header}>Manage TROVE Administrators</p>

            <div className={styles.table}>
              <div className={styles.first_row}>
                <div className={styles.label_sortable}>
                  <p>User</p>
                  <img
                    src={ic_arrow_sort}
                    alt="Sort By Employee Name"
                    onClick={onSortReportByEmployeeName}
                    className={!isAcendinglySortedbyName ? styles.sort_image_rotated : ""}
                  />
                </div>
                <div className={styles.label}>
                  <p>Admin</p>
                </div>
              </div>
              {Array.isArray(admins) && admins.length !== 0 ? (
                admins.map((admin) => (
                  <div key={admin.employee_core_id}>
                    <div className={styles.employee_row}>
                      <p className={styles.employee_name_column}>
                        {admin.last_name}, {admin.first_name}
                      </p>
                      <input
                        type="checkbox"
                        checked
                        onChange={() =>
                          removeAdminHandler(
                            admin.employee_core_id,
                            admin.first_name,
                            admin.last_name,
                          )
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>There are no admins.</p>
              )}
            </div>
          </div>

          <div className={styles.admin_add}>
            <p className={styles.section_header}>Add Trove Administrator</p>
            <Select
              className={styles.dropdown}
              value={adminsToAdd}
              placeholder="Search User..."
              isMulti
              closeMenuOnSelect={false}
              onChange={updateAdminListHandler}
              options={
                Array.isArray(nonAdmins) && nonAdmins.length !== 0
                  ? nonAdmins.map((employee: AdminInterface) => ({
                      value: employee.employee_core_id,
                      label: `${employee.first_name} ${employee.last_name}`,
                    }))
                  : [{ value: "None", label: "There are no users." }]
              }
            />
            <button className={styles.add_button} type="button" onClick={addAdminHandler}>
              Add
            </button>
          </div>

          <div>
            <p className={styles.section_header}>Inappropriate Content Reports</p>
            {Array.isArray(inappropriateReviews) && inappropriateReviews.length !== 0 ? (
              inappropriateReviews.map((review) => (
                <ReviewModal
                  key={review.employee_core_id + review.course_id}
                  coreId={review.employee_core_id}
                  courseId={review.course_id}
                  title={review.title}
                  initialReview={review.review}
                  onFinish={() => setDoesNeedRender(true)}
                />
              ))
            ) : (
              <p className={styles.empty_message}>There are no reports.</p>
            )}
          </div>
        </div>
      ) : (
        <h2 className={styles.not_authorized}>You are not authorized to view this page!</h2>
      )}
    </div>
  );
}

export default AdminPage;
