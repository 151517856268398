import mixpanel, { Dict } from "mixpanel-browser";
import config from "../config";

const ENV_CHECK: boolean = !!config.mixpanelProjectToken;

if (ENV_CHECK) mixpanel.init(config.mixpanelProjectToken!);

const Mixpanel = {
  identify: (unique_id: string) => {
    if (ENV_CHECK) mixpanel.identify(unique_id);
  },
  reset: () => {
    if (ENV_CHECK) mixpanel.reset();
  },
  track: (event_name: string, properties?: Dict) => {
    if (ENV_CHECK) mixpanel.track(event_name, properties);
  },
  people: {
    set: (prop: Dict) => {
      if (ENV_CHECK) mixpanel.people.set(prop);
    },
  },
};

export default Mixpanel;
