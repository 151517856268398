import React, { useCallback, Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import classes from "./CourseDeleteModal.module.scss";

interface CourseDeleteModalProp {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: (shouldDelete: boolean) => void;
}

function CourseDeleteModal({ setIsModalOpen, onDelete }: CourseDeleteModalProp) {
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const deleteHandler = () => {
    onDelete(true);
  };

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      contentLabel="Delete Modal"
      className={classes.PopupWindow}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
      parentSelector={() => document.body}
    >
      <b>Course Deletion Confirmation</b>
      <hr />
      <div>
        <p>Are you sure you want to delete this course?</p>
        <p>You will be redirected to the Home Page.</p>
        <div className={classes.buttons}>
          <button type="button" className={classes.cancel} onClick={closeModal}>
            Cancel
          </button>
          <button type="button" onClick={deleteHandler}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CourseDeleteModal;
