import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Header from "../shared/Header/Header";
import MyCoursesTab from "./MyCoursesComponent/MyCoursesComponent";
import MyWishlistTab from "./MyWishlistComponent/MyWishlistComponent";
import tabsStyles from "./MyTrovePage.module.scss";
import SettingIcon from "../assets/images/ic_settings.svg";
import Mixpanel from "../shared/MixPanelService";

/* Function below searches the URL string to determine which tab should be active */
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MyTrovePage() {
  /* Search URL to get the tab that should be active */
  const selectedTab = useQuery().get("tab");
  const [activeTab, setActiveTab] = useState(selectedTab);

  useEffect(() => {
    // Mixpanel Tracking for Page Visit
    Mixpanel.track("Page Visit", {
      Page: "MyTrovePage",
      InitialTab: selectedTab,
    });
  }, []);

  /**
   * Sets the state of the active tab which is displayed
   * @param newTabName name of the new active tab which we will be displaying
   */
  const history = useHistory();
  const SwitchHandler = (newTabName: string) => {
    setActiveTab(newTabName);
    if (newTabName === "myCoursesTab") {
      Mixpanel.track("MyTrovePage - Tab Switch", {
        Tab: "MyCourses",
      });
      history.push("/profile?tab=myCoursesTab");
    } else {
      Mixpanel.track("MyTrovePage - Tab Switch", {
        Tab: "MyWishlist",
      });
      history.push("/profile?tab=wishlistTab");
    }
  };

  return (
    <div>
      <Header heading="MyTROVE" usesAclonicaFontHeading />
      <div className={tabsStyles["tabs-container"]}>
        <ul className={tabsStyles.nav}>
          <li
            onClick={() => SwitchHandler("myCoursesTab")}
            className={activeTab === "myCoursesTab" ? "active" : "inactive"}
            aria-hidden="true"
          >
            <div>
              <div className={tabsStyles["settings-icon"]}>
                <img src={SettingIcon} alt="mySvgImage" />
                My Courses
              </div>
            </div>
          </li>
          <li
            onClick={() => SwitchHandler("wishlistTab")}
            className={activeTab === "wishlistTab" ? "active" : "inactive"}
            aria-hidden="true"
          >
            <div className={tabsStyles["settings-icon"]}>
              <img src={SettingIcon} alt="mySvgImage" />
              My Wishlist
            </div>
          </li>
        </ul>
        {activeTab === "myCoursesTab" ? <MyCoursesTab /> : <MyWishlistTab />}
      </div>
    </div>
  );
}

export default MyTrovePage;
