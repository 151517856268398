import React, { useCallback, useState, ChangeEvent, useEffect } from "react";
import Modal from "react-modal";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../config";
import styles from "./ReviewModal.module.scss";

function ReviewModal(props: {
  coreId: string;
  courseId: number;
  title: string;
  initialReview: string;
  onFinish: () => void;
}) {
  const { authState, oktaAuth } = useOktaAuth();
  // Whether the modal is open or not
  const [modalIsOpen, setIsOpen] = useState(false);
  // Whether to display edit functionality or not
  const [isInEditMode, setIsInEditMode] = useState(false);
  // Whether to display delete prompt or not
  const [isInDeleteMode, setIsInDeleteMode] = useState(false);
  // Whether to display resolve prompt or not
  const [isInResolveMode, setIsInResolveMode] = useState(false);
  // Current review to inspect
  const [review, setReview] = useState("");
  const { coreId, courseId, title, initialReview, onFinish } = props;

  /**
   * Sets review to hold the user's initial review.
   */
  useEffect(() => {
    setReview(initialReview);
  }, []);

  /**
   * Sets the modal to be open.
   */
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  /**
   * Sets the modal to be closed.
   */
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  /**
   * Calls the function passed in as a prop to update the page after changes are made to a review.
   */
  const finishHandler = () => {
    onFinish();
  };

  /**
   * Deletes a review and closes the modal.
   */
  const deleteReviewHandler = () => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${coreId}/course/${courseId}/review`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      }).then((response) => {
        response.json().then(() => {
          closeModal();
          finishHandler();
        });
      });
    }
  };

  /**
   * If the modal is not in edit mode, sets the modal to display functionality for editing a review.
   * If the model is in edit mode, update the review and switch modal to main mode.
   */
  const toggleEditModeHandler = () => {
    if (isInEditMode) {
      if (authState && authState.isAuthenticated) {
        fetch(`${config.resourceServer}/review/edit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          },
          body: JSON.stringify({
            employee_core_id: coreId,
            course_id: courseId,
            review,
          }),
        }).then((response) => {
          response.json().then(() => {
            setIsInEditMode(!isInEditMode);
          });
        });
      }
    } else {
      setIsInEditMode(!isInEditMode);
    }
  };

  /**
   * Updates the review state with the edited review
   * @param event triggered by admin editing review
   */
  const reviewEditHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReview(event.target.value);
  };

  /**
   * Marks the review as appropriate, and closes the modal.
   */
  const resolveReviewHandler = () => {
    if (authState && authState.isAuthenticated) {
      fetch(`${config.resourceServer}/employee/${coreId}/course/${courseId}/review`, {
        method: "POST",
        headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` },
      }).then((response) => {
        response.json().then(() => {
          closeModal();
          finishHandler();
        });
      });
    }
  };

  return (
    <div>
      <p className={styles.content_report} onClick={openModal}>
        {title}
      </p>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="View Review"
        className={styles.popup_window}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <button className={styles.close_button} type="button" onClick={closeModal}>
          &times;
        </button>
        <h2 className={styles.header_text}>
          {coreId}&apos;s Review For {title}
        </h2>
        <hr />

        {isInDeleteMode ? (
          <div>
            <h3 className={styles.confirm_message}>Are you sure you want to delete this review?</h3>
            <p>&quot;{review}&quot;</p>
            <div className={styles.functions}>
              <button type="button" className={styles.delete_button} onClick={deleteReviewHandler}>
                Delete
              </button>
              <button
                className={styles.other_buttons}
                type="button"
                onClick={() => {
                  setIsInDeleteMode(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : isInResolveMode ? (
          <div>
            <h3 className={styles.header_text}>
              Are you sure you want to resolve this review and mark it as appropriate?
            </h3>
            <p>&quot;{review}&quot;</p>
            <div className={styles.functions}>
              <button
                type="button"
                className={styles.resolve_button}
                onClick={resolveReviewHandler}
              >
                Resolve
              </button>
              <button
                type="button"
                className={styles.other_buttons}
                onClick={() => {
                  setIsInResolveMode(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : isInEditMode ? (
          <div>
            <textarea
              className={styles.edit_box}
              value={review}
              maxLength={800}
              onChange={reviewEditHandler}
            />
            <button type="button" onClick={toggleEditModeHandler}>
              Done Editing
            </button>
          </div>
        ) : (
          <div>
            <p>&quot;{review}&quot;</p>
            <div className={styles.functions}>
              <button
                type="button"
                className={styles.delete_button}
                onClick={() => setIsInDeleteMode(true)}
              >
                Delete Review
              </button>
              <button
                type="button"
                className={styles.other_buttons}
                onClick={toggleEditModeHandler}
              >
                Edit Review
              </button>
              <button
                type="button"
                className={styles.other_buttons}
                onClick={() => setIsInResolveMode(true)}
              >
                Mark as Resolved
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ReviewModal;
