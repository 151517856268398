import React from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import classes from "./Header.module.scss";
import NavMenu from "../NavMenu/NavMenu";

interface HeaderProps {
  heading: string;
  usesAclonicaFontHeading?: boolean;
  description?: string;
  image?: string;
  hasWhiteIcon?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
Header.defaultProps = {
  usesAclonicaFontHeading: false,
  description: undefined,
  image: undefined,
  hasWhiteIcon: false,
};

/* Displays header for the My Trove page. Includes the user's profile picture, name, and the dropdown menu. */
function Header({
  heading,
  usesAclonicaFontHeading,
  description,
  image,
  hasWhiteIcon,
}: HeaderProps) {
  const fullName = useOktaAuth().authState?.idToken?.claims.name;

  return (
    <div className={classes.wrapper}>
      <div className={classes["navigation-section"]}>
        {hasWhiteIcon ? <NavMenu hasWhiteIcon /> : <NavMenu hasWhiteIcon={false} />}
        <Link to="/" className={classes.logo}>
          TROVE
        </Link>
      </div>
      <div className={classes["title-section"]}>
        {usesAclonicaFontHeading ? (
          <div className={classes["title-with-aclonica-font"]}>{heading}</div>
        ) : description !== undefined ? (
          <div className={classes["title-with-description"]}>
            <div>{heading}</div>
            <div className={classes.description}> {description}</div>
          </div>
        ) : image !== undefined ? (
          <div className={classes["title-with-image"]}>
            <div> {heading} </div>
            <img src={image} alt="" />
          </div>
        ) : (
          <div>{heading}</div>
        )}
      </div>
      <div className={classes["user-section"]}>
        <div className={classes["user-avatar"]}>
          <Avatar name={`${fullName}`} size="60" round />
        </div>
        <p className={classes["user-avatar-name"]}>{fullName}</p>
      </div>
    </div>
  );
}

export default Header;
